<template>
  <div
    class="flex items-center transition-all duration-500 ease-in-out overflow-hidden mb-4 mt-6"
    :class="[self.promoTimer.isActive && !self.isOrganic ? 'h-14' : 'h-0']"
    data-test-id="timer_block_top_prp"
  >
    <div class="text-headline6 font-semibold flex-grow flex items-center justify-start">
      {{ t('expires') }}
    </div>
    <div class="flex text-headline4 font-semibold text-center gap-1 font-mono text-error">
      <div>
        {{ self.promoTimer.timeLeftFormatted.mm }}
        <div class="text-overline text-dark-200 font-medium">{{ t('min') }}</div>
      </div>
      <div>:</div>
      <div>
        {{ self.promoTimer.timeLeftFormatted.ss }}
        <div class="text-overline text-dark-200 font-medium">{{ t('sec') }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onUnmounted } from 'vue';

import { useSelf } from '@/components/ntf-modal/components/ntf-premium-v2/components/premium-timer/hooks/use-self/use-self';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const { t } = useI18n({ messages });

const self = useSelf();

onUnmounted(() => {
  self.destroy();
});

self.init();
</script>
