import ProlongationCancelledModal from '@/components/prolongation-modal/components/prolongation-cancelled-modal/ProlongationCancelledModal.vue';
import {
  PaymentStateSubscription,
  PaymentStateSubscriptionState,
} from '@/pages/payment/store/payment-state';
import { useModal } from '@/services/modal/modal';
import { getState } from '@/store/store-helper';

export function showCancelledModal(subscription: PaymentStateSubscription) {
  const isSpecial = getState().common.index.isSpecial;

  if (isSpecial) {
    return;
  }

  useModal(() => ({
    component: ProlongationCancelledModal,
    props: {
      subscription,
    },
  })).showModal();
}

export function getIsCancelledModalAvailable(status: PaymentStateSubscriptionState) {
  return status === 'inactive';
}
