<template>
  <div
    data-id="notification-report"
    class="alert-info flex report"
    data-test-id="acc_deactivated_notification"
  >
    <div class="alert-info-icon upload flex items-center justify-center">
      <div class="img-section">
        <i class="icon icon-report"></i>
      </div>
    </div>
    <div class="alert-info-body grow flex items-center">
      <div class="alert-info-section">
        {{ t('text') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const { t } = useI18n({ messages });
</script>
