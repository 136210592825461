import {
  ConnectionState,
  PowerLikeRequest_Status,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/connections/public/v1/dialogs_pb';
import {
  ProfileBodyType,
  ProfileChildren,
  ProfileDrinking,
  ProfileEducation,
  ProfileEthnicity,
  ProfileEyeColor,
  ProfileGender,
  ProfileHairColor,
  ProfileHeightUnits,
  ProfileLocation,
  ProfileLookingFor,
  ProfileMaritalStatus,
  ProfilePhoto,
  ProfileReligion,
  ProfileSexualOrientation,
  ProfileSmoking,
  ProfileSphereOfWork,
  ProfileSport,
  VerifiedBy,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_pb';

import { ExtractClassFields } from '@/type';

export enum ProfileStateAbuseReasonNew {
  BLOCK = 1,
  UNCOMFORTABLE = 2,
  ABUSIVE = 3,
  INAPPROPRIATE = 4,
  SPAM = 5,
  STOLEN_PHOTO = 6,
  OTHER = 7,
}

export enum ProfileStateAbuseReason {
  RUDE = 1,
  CONTENT = 2,
  FAKE_PHOTOS = 3,
  SPAM = 4,
  SCAM = 5,
  AGE = 6,
  OTHER = 7,
}

export type ProfileStateFieldSettingsName =
  | 'sphere_of_work'
  | 'religion'
  | 'sexual_orientation'
  | 'marital_status'
  | 'ethnicity'
  | 'body_type'
  | 'hair_color'
  | 'eye_color'
  | 'sport'
  | 'children'
  | 'children_count'
  | 'drinking'
  | 'smoking';

export type ProfileStateFieldSettings = {
  fieldName: ProfileStateFieldSettingsName;
  isLocked: boolean;
};

export type ProfileStateProfile = {
  id: string;
  gender: ProfileGender;
  about: string;
  sphereOfWork: ProfileSphereOfWork;
  position: string;
  interests: string[];
  birthPlace: string;
  education: ProfileEducation;
  religion: ProfileReligion;
  sexualOrientation: ProfileSexualOrientation;
  maritalStatus: ProfileMaritalStatus;
  ethnicity: ProfileEthnicity;
  heightValue: number;
  heightUnit: ProfileHeightUnits;
  bodyType: ProfileBodyType;
  hairColor: ProfileHairColor;
  children: ProfileChildren;
  drinking: ProfileDrinking;
  smoking: ProfileSmoking;
  location: ExtractClassFields<ProfileLocation>;
  distance: number;
  isLocationUpdateAllowed: boolean;
  isLocationDetectionAllowed: boolean;
  photos: ExtractClassFields<ProfilePhoto>[];
  name: string;
  age: number;
  matchGender: ProfileGender;
  lookingFor: ProfileLookingFor[];
  isVerified: boolean;
  isOnline: boolean;
  isBlocked: boolean;
  isLiked: boolean;
  hasBlockedByUser: boolean;
  isPhotoRequested: boolean;
  isDeleted: boolean;
  fieldSettings: ProfileStateFieldSettings[];
  chatId: string;
  eyeColor: ProfileEyeColor;
  sport: ProfileSport;
  educationalInstitution: string;
  verifiedByList: VerifiedBy[];
  isSpecial: boolean;
};

export type ProfileState = {
  profiles: { [key: string]: ProfileStateProfile };
  connections: ProfileConnectionState;
  connectionsLoading: { [key: string]: boolean };
};

export const state: ProfileState = {
  profiles: {},
  connections: {},
  connectionsLoading: {},
};

export type ProfileConnectionState = {
  [key: string]: ExtractClassFields<ConnectionState>;
};

export type ProfileConnectionPowerLikeStatus = { userId: string; status: PowerLikeRequest_Status };

// export type ProfileConnectionPowerLikeDuration = {
//   expiresAt: number;
//   expireDuration: number;
//   idUser: string;
//   messagesCount: number;
// };
