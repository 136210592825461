import { I18n } from '@/services/i18n';

type Message = {
  creditsAdded: string;
};

const messages: I18n<Message> = {
  en: {
    creditsAdded: 'Credits added',
  },
  es: {
    creditsAdded: 'Créditos añadidos',
  },
  de: {
    creditsAdded: 'Credits hinzugefügt',
  },
};

export { messages };
