import { I18n } from '@/services/i18n';

type Message = {
  popular: string;
  save: string;
  best: string;
  tax: string;
};

const messages: I18n<Message> = {
  en: {
    popular: 'Most popular',
    save: 'Save',
    best: 'Best deal',
    tax: 'tax',
  },
  es: {
    popular: 'Más popular',
    save: 'Ahorrar',
    best: 'Mejor oferta',
    tax: 'impuesto',
  },
  de: {
    popular: 'Am beliebtesten',
    save: 'Sparen',
    best: 'Bestes Angebot',
    tax: 'Steuer',
  },
};

export { messages };
