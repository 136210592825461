import { I18n } from '@/services/i18n';

type Message = {
  suspended: string;
  wouldYouLikeToTakeCancelled: string;
  reactivate: string;
};

const messages: I18n<Message> = {
  en: {
    suspended: 'Your {tier}<br />membership is suspended',
    wouldYouLikeToTakeCancelled:
      'Would you like to take care of it and reactivate now to keep using the platform?',
    reactivate: 'reactivate',
  },
  es: {
    suspended: 'Tu membresía {tier}<br />está suspendida',
    wouldYouLikeToTakeCancelled:
      '¿Te gustaría encargarte de esto y reactivar ahora para seguir usando la plataforma?',
    reactivate: 'reactivar',
  },
  de: {
    suspended: 'Ihre {tier}<br />Mitgliedschaft ist ausgesetzt',
    wouldYouLikeToTakeCancelled:
      'Möchten Sie es jetzt erledigen und jetzt reaktivieren, um die Plattform weiterhin nutzen zu können?',
    reactivate: 'reaktivieren',
  },
};

export { messages };
