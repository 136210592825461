import {
  type Event_Device,
  type Event_Geo,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/analytics/event_pb';
import { UAParser } from 'ua-parser-js';

import { isCrawlerFb, isSubdomainLp } from '@/helpers/navigator/navigator';
import { analyticsApi } from '@/services/api/analytics/analytics-api';
import { fetchGeo } from '@/services/geolocation';
import { ExtractClassFields } from '@/type';

type Settings = {
  geo: ExtractClassFields<Event_Geo>;
  device: ExtractClassFields<Event_Device>;
  userId: string;
};

const settings: Settings = {
  geo: {
    city: '',
    country: '',
    state: '',
  },
  device: {
    browserName: '',
    browserVersion: '',
    browserType: '',
    language: '',
    osName: '',
    osVersion: '',
    platformModel: '',
    platformName: '',
    platformVendor: '',
  },
  userId: '',
};

let promiseInit: Promise<void>;

function initSettings(geo: Settings['geo'], userId: string) {
  const uaParser = UAParser(window.navigator.userAgent);

  let browserType = uaParser.browser.type ?? 'web';

  if (isCrawlerFb()) {
    browserType = 'crawler';
  }

  Object.assign(settings, {
    geo,
    device: {
      browserName: uaParser.browser.name ?? 'unknown',
      browserVersion: uaParser.browser.version ?? 'unknown',
      browserType,
      language: window.navigator.language,

      osName: uaParser.os.name ?? 'unknown',
      osVersion: uaParser.os.version ?? 'unknown',

      platformModel: uaParser.device.model ?? 'unknown',
      platformName: uaParser.device.type ?? 'desktop',
      platformVendor: uaParser.device.vendor ?? 'unknown',
    },
    userId,
  } as Settings);
}

function init(userId: string) {
  if (!promiseInit) {
    promiseInit = new Promise((resolve) => {
      fetchGeo()
        .then((geo) => {
          initSettings(
            {
              city: geo?.city?.name ?? 'unknown',
              country: geo?.country?.name ?? 'unknown',
              state: geo?.state?.name ?? 'unknown',
            },
            userId,
          );

          resolve();
        })
        .catch(() => {
          initSettings(
            {
              city: 'unknown',
              country: 'unknown',
              state: 'unknown',
            },
            userId,
          );

          resolve();
        });
    });
  }

  return promiseInit;
}

async function track(eventName: string, eventParamList: Record<string, unknown>) {
  if (promiseInit) {
    if (isSubdomainLp()) {
      eventName = `lp-${eventName}`;
    }

    await promiseInit.then(() => {
      return analyticsApi.recordEvent({
        ...settings,
        name: eventName,
        params: Object.entries(eventParamList).map(([key, value]) => ({
          key,
          value: String(value),
        })),
      });
    });
  }
}

export const bq = {
  init,
  track,
};
