import { FeatureType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/market/v1/package_pb';

import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';
import { showCreditsOffer } from '@/pages/payment/helpers/credits-offer/credits-offer';
import { CommonStateCreditsFeatures } from '@/store/modules/common/common-state';
import { dispatch, getState } from '@/store/store-helper';

export function hasFreeCreditsFeature(feature: FeatureType): boolean {
  const features: CommonStateCreditsFeatures = getState().common.creditsFeatures;

  return (features[feature] || 0) > 0;
}

const featureTitleMap: Record<FeatureType, string> = {
  [FeatureType.INVALID]: 'invalid',
  [FeatureType.LIKES_LIMIT]: 'likes_limit',
  [FeatureType.MESSAGES_LIMIT]: 'messages_limit',
  [FeatureType.TOP_PROFILE]: 'top_profile',
  [FeatureType.ADVANCED_SEARCH]: 'advanced_search',
  [FeatureType.PREMIUM]: 'premium',
  [FeatureType.USER_UNBLOCK]: 'user_unblock',
  [FeatureType.ALLOW_WHO_LIKED_YOU]: 'allow_who_liked_you',
  [FeatureType.ALLOW_DELETE_OWN_MESSAGE]: 'allow_delete_own_message',
  [FeatureType.MESSAGE_SIZE]: 'message_size',
  [FeatureType.PROFILE_DETAILS]: 'profile_details',
  [FeatureType.READ_ACTIVITY]: 'read_activity',
  [FeatureType.GIFT]: 'gift',
  [FeatureType.TOP_PROFILE_PLUS]: 'top_profile_plus',
  [FeatureType.ASTROLOGY_CHECK]: 'astrology_check',
  [FeatureType.SEXUAL_CHECK]: 'sexual_check',
  [FeatureType.POWER_LIKE]: 'power_like',
  [FeatureType.POWER_DONATION]: 'power_donation',
  [FeatureType.PHOTO_REQUEST]: 'photo_request',
};

export async function isAllowedCreditsAction(
  priceCredits: number,
  feature: FeatureType = FeatureType.INVALID,
  // Parameter `source` is for analytics purposes.
  source: string = 'unknown',
): Promise<boolean> {
  if (getState().common.index.isSpecial) {
    showCreditsOffer(source);
    return false;
  }

  const { isAutorefillAllowed, isAutorefillEnabled, creditsCount } = await dispatch(
    'actionPaymentGetCreditsBalance',
  );

  await dispatch('actionCommonGetFreeCreditsFeaturesStatus');

  const isAutoRefill = isAutorefillAllowed && isAutorefillEnabled;
  const isFreeFeature = feature !== FeatureType.INVALID && hasFreeCreditsFeature(feature);
  const isEnoughCredits = creditsCount >= priceCredits;
  const isAllowed = isAutoRefill || isFreeFeature || isEnoughCredits;

  if (!isAllowed) {
    showCreditsOffer(source);
  }

  customEventTrigger({
    event: 'credits_feature_activate',
    result: isAllowed ? 'success' : 'fail',
    feature: featureTitleMap[feature],
    source,
  });

  return isAllowed;
}

export function getIsApplePayAvailable(): boolean {
  // @ts-expect-error
  return window.ApplePaySession;
}
