import * as growthBook from '@project-gd-x/shared-web/src/helpers/growth-book/growth-book';

import { getUserId } from '@/helpers/auth/auth';
import { isAutotest } from '@/helpers/autotest/autotest';
import { parseCookie } from '@/helpers/cookie/cookie';
import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';
import { isParamNtf } from '@/helpers/navigator/navigator';
import { hasCache, setCache } from '@/helpers/persistent-cache';

export type GrowthBookFeatures = {
  // 'welcome-popup': 'v1' | 'v2';
  // 'paypal-first-split': 'v1' | 'v2';
  // 'trial-prosperi2': 'v1' | 'v2';
  // 'trial-prosperi3': 'v1' | 'v2';
  // 'paypal-improve': 'v1' | 'v2';
  // 'trial-prosperi': 'v1' | 'v2';
  // webview: 'v1' | 'v2';
  // 'big-search': 'v1' | 'v2';
  // gpay2: 'v1' | 'v2' | 'def';
  // 'no-welcome': 'v1' | 'v2' | 'def';

  //https://gdx.myjetbrains.com/youtrack/issue/GDP-4050/Close-split-ntf21
  // ntf21: 'v1' | 'v2' | 'def';

  // https://gdx.myjetbrains.com/youtrack/issue/GDP-3853/Close-split-like-popup
  // 'like-popup': 'v1' | 'v2' | 'v3' | 'v4' | 'def';

  'pr-aa': 'v1' | 'v2' | 'def';

  // https://gdx.myjetbrains.com/youtrack/issue/GDP-3913/Close-split-merge-notif2
  // 'merge-notif2': 'v1' | 'v2' | 'v3' | 'def';

  // https://gdx.myjetbrains.com/youtrack/issue/GDP-3864/No-Engagement-Test
  'no-engag': 'v1' | 'v2' | 'def';

  // https://gdx.myjetbrains.com/youtrack/issue/GDP-3806/Onboarding-Android-veb-view-photo
  // https://gdx.myjetbrains.com/youtrack/issue/GDP-3895/EndTest-Onboarding-android-upload
  // 'android-upload': 'v1' | 'v2' | 'def';

  // https://gdx.myjetbrains.com/youtrack/issue/GDP-3922/NTF-Deactivation-by-Support
  // deactivate: 'v1' | 'v2' | 'v3' | 'def';

  // https://gdx.myjetbrains.com/youtrack/issue/GDP-4086/Close-split-deactivate2
  // deactivate2: 'v1' | 'v2' | 'def';

  // https://gdx.myjetbrains.com/youtrack/issue/GDP-3790/frontend-Trial-Expiration-Reminder-Welcome-Popup
  // reminder: 'ntf-v2' | 'trf-v2' | 'def';

  'hide-geo': 'v1' | 'v2' | 'def';

  // https://gdx.myjetbrains.com/youtrack/issue/GDP-4205/Project-EM-1010
  'project-em': 'v1' | 'v2' | 'v3' | 'v4' | 'v5' | 'def';

  // https://gdx.myjetbrains.com/youtrack/issue/GDP-4318/Split-Clear-Credits-Paywall
  'credits-paywall': 'v1' | 'v2' | 'v3' | 'v4' | 'def';

  // https://gdx.myjetbrains.com/youtrack/issue/GDP-4470/Split-Buttons-redesign
  'buttons-redesign': 'v1' | 'v2' | 'def';

  // https://gdx.myjetbrains.com/youtrack/issue/GDP-4492/Split-activity3
  activity3: 'v1' | 'v2' | 'def';
};

export type GrowthBookAttributesGender = 'male' | 'female';

export type GrowthBookAttributes = {
  userId: string;
  isNtf: boolean;
  regDateUnix?: number;
  gender?: GrowthBookAttributesGender;
};

export async function initGrowthBook() {
  return growthBook.initGrowthBook<GrowthBookFeatures>({
    clientKey: import.meta.env.VUE_APP_GROWTH_BOOK_CLIENT_KEY,
    apiHost: import.meta.env.VUE_APP_GROWTH_BOOK_API_HOST,

    trackingCallback: (experiment, result) => {
      const cacheKey = `exp_${experiment.key}_${result.key}`;

      if (hasCache(cacheKey)) {
        return;
      }

      customEventTrigger({
        event: 'experiment_viewed',
        event_category: 'experiment',
        experiment_id: experiment.key,
        variation_id: result.key,
      });
      setCache(cacheKey, '1');
    },

    // features: {
    //   'book-upsale': {
    //     defaultValue: 'v1',
    //     rules: [
    //       {
    //         variations: ['v1', 'v2', 'v3'],
    //         coverage: 1,
    //         weights: [0.3334, 0.3333, 0.3333],
    //         hashAttribute: 'userId',
    //       },
    //     ],
    //   },
    // },
  });
}

export async function getGrowthBook() {
  return growthBook.getGrowthBook<GrowthBookFeatures>();
}

export async function setGrowthBookAttributes(attributes: GrowthBookAttributes) {
  await growthBook.setGrowthBookAttributes(attributes);
}

// Just use this method, don't run any init methods before.
export async function getGrowthBookFeatureValue<T extends keyof GrowthBookFeatures>(
  featureName: T,
  defaultValue: GrowthBookFeatures[T],
  attributeAdditionalList?: Partial<GrowthBookAttributes>,
): Promise<GrowthBookFeatures[T]> {
  const cookieList = parseCookie(document.cookie);
  const featureValue = cookieList[`gdx_split_${featureName}`];

  if (featureValue) {
    return featureValue as GrowthBookFeatures[T];
  } else if (isAutotest()) {
    return 'def';
  }

  await setGrowthBookAttributes({
    userId: getUserId(),
    isNtf: isParamNtf(),
    ...attributeAdditionalList,
  });

  const gb = await getGrowthBook();

  return gb.getFeatureValue(featureName, defaultValue) as GrowthBookFeatures[T];
}
