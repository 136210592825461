import { I18n } from '@/services/i18n';

type Message = {
  continue: string;
  hint: string;
  header: string;
};

const messages: I18n<Message> = {
  en: {
    continue: 'Continue',
    hint: 'You will be charged',
    header: 'Payment methods',
  },
  es: {
    continue: 'Continuar',
    hint: 'Se te realizará un cargo',
    header: 'Métodos de pago',
  },
  de: {
    continue: 'Fortfahren',
    hint: 'Sie werden abgerechnet',
    header: 'Zahlungsmethoden',
  },
};

export { messages };
