import { I18n } from '@/services/i18n';

type Message = {
  yourPhotoWasRequested: string;
  wantsToSeeWhatYouLook: string;
  uploadPhoto: string;
};

const messages: I18n<Message> = {
  en: {
    yourPhotoWasRequested: 'Your photo was requested by...',
    wantsToSeeWhatYouLook: 'wants to see what you look like and asked you to upload a photo',
    uploadPhoto: 'Upload photo',
  },
  es: {
    yourPhotoWasRequested: 'Tu foto ha sido solicitada por...',
    wantsToSeeWhatYouLook: 'quiere ver cómo te ves y te pidió que subas una foto',
    uploadPhoto: 'Subir foto',
  },
  de: {
    yourPhotoWasRequested: 'Ihr Foto wurde angefordert von...',
    wantsToSeeWhatYouLook: 'will sehen, wie Sie aussehen und hat Sie gebeten, ein Foto hochzuladen',
    uploadPhoto: 'Foto hochladen',
  },
};

export { messages };
