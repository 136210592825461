import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { I18n } from '@/services/i18n';

type Message = {
  slider: Record<
    Exclude<SubscriptionFeature, SubscriptionFeature.UNLOCK_LOCATION>,
    {
      header: string;
      content: string;
    }
  >;
};

const messages: I18n<Message> = {
  en: {
    slider: {
      unlimitedLikes: {
        header: 'Unlimited likes',
        content: 'You have infinite likes',
      },
      advancedSearch: {
        header: 'Advanced Search',
        content: 'Get all the information you need',
      },
      unblockUsers: {
        header: 'Unblock users',
        content: 'You can block and unblock users when you feel the need',
      },
      unlimitedMessages: {
        header: 'Unlimited chatting',
        content: 'No limits on chatting and messaging',
      },
      deleteOwnMessages: {
        header: 'Delete own messages',
        content: 'You can delete a message if it was sent less than 24 hours ago',
      },
      advancedInformation: {
        header: 'Advanced information',
        content: "You can see every users' advanced information in their profiles",
      },
      activity: {
        header: 'Activities',
        content: 'You can see all incoming & outgoing activities',
      },
      ads: {
        header: 'No Ads',
        content: 'Tired of ads? Upgrade and don’t get bothered by advertising',
      },
    },
  },
  es: {
    slider: {
      unlimitedLikes: {
        header: 'Me gusta ilimitados',
        content: 'Tienes me gusta infinitos',
      },
      advancedSearch: {
        header: 'Búsqueda avanzada',
        content: 'Obtén toda la información que necesitas',
      },
      unblockUsers: {
        header: 'Desbloquear usuarios',
        content: 'Puedes bloquear y desbloquear usuarios cuando lo necesites',
      },
      unlimitedMessages: {
        header: 'Chat ilimitado',
        content: 'No hay límites en chatear y enviar mensajes',
      },
      deleteOwnMessages: {
        header: 'Eliminar mis mensajes',
        content: 'Puedes eliminar un mensaje si fue enviado hace menos de 24 horas',
      },
      advancedInformation: {
        header: 'Información avanzada',
        content: 'Puedes ver la información avanzada de cada usuario en sus perfiles',
      },
      activity: {
        header: 'Actividades',
        content: 'Puedes ver todas las actividades entrantes y salientes',
      },
      ads: {
        header: 'Sin anuncios',
        content: '¿Cansado de los anuncios? Mejora tu cuenta y no te molestarán las publicidades',
      },
    },
  },
  de: {
    slider: {
      unlimitedLikes: {
        header: 'Unbegrenzte Likes',
        content: 'Sie haben unendliche Likes',
      },
      advancedSearch: {
        header: 'Erweiterte Suche',
        content: 'Erhalten Sie alle Informationen, die Sie benötigen',
      },
      unblockUsers: {
        header: 'Benutzer entsperren',
        content: 'Sie können Benutzer blockieren und entsperren, wann immer Sie wollen',
      },
      unlimitedMessages: {
        header: 'Unbegrenztes Chatten',
        content: 'Keine Begrenzungen beim Chatten und Nachrichten',
      },
      deleteOwnMessages: {
        header: 'Eigene Nachrichten löschen',
        content:
          'Sie können eine Nachricht löschen, wenn sie vor weniger als 24 Stunden gesendet wurde',
      },
      advancedInformation: {
        header: 'Erweiterte Informationen',
        content: 'Sie können erweiterte Informationen aller Benutzer in ihren Profilen sehen',
      },
      activity: {
        header: 'Aktivitäten',
        content: 'Sie können alle eingehenden und ausgehenden Aktivitäten sehen',
      },
      ads: {
        header: 'Keine Werbung',
        content: 'Genervt von Werbung? Upgrade und lassen Sie sich nicht mehr durch Werbung stören',
      },
    },
  },
};

export { messages };
