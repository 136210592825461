<template>
  <div
    data-id="notification-report"
    class="alert-info flex report"
    data-test-id="notification_report"
  >
    <div class="alert-info-icon upload flex items-center justify-center">
      <div class="img-section">
        <i class="icon icon-report"></i>
      </div>
    </div>
    <div class="alert-info-body grow flex items-center">
      <div v-if="isAbuseAction" class="alert-info-section">{{ t('reportSuccess') }}</div>
      <div v-else class="alert-info-section">{{ t('blockSuccess') }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

defineProps({
  isAbuseAction: {
    type: Boolean,
    required: true,
  },
});

const { t } = useI18n({ messages });
</script>
