<template>
  <div v-if="isVisible">
    <ModalSpinner v-if="isLoaderVisible"></ModalSpinner>
    <ModalMenu class-name="web_push_modal">
      <template #title>
        <a href="#" class="web_push_modal-close" data-test-id="btn_close" @click.prevent="close">
          <i class="icon icon-close"></i>
        </a>

        <div class="web_push_modal-avatar relative top-profile">
          <div class="overflow-hidden rounded-full">
            <RetinaPhoto :photo-id="avatarId" :width="80" :height="80"></RetinaPhoto>
          </div>
          <div class="web_push_modal-icon top-profile">
            <i class="icon icon-top_profile"></i>
          </div>
        </div>

        {{ t('header') }}
      </template>
      <template #text>
        <div class="web_push_modal-content">
          {{ t('modalText', { duration, title: promoModal.booster?.title ?? '' }) }}
          <br />
          {{ t('forJust') }}&nbsp;
          <SvgIcon name="coin" class="booster-coin_img d-inline" :width="16" :height="16" />
          {{ promoModal.booster?.priceCredits }} {{ t('credits') }}.
        </div>

        <button type="button" class="btn btn-primary btn-block" @click="applyBooster()">
          {{ t('becomeStar') }}
        </button>
      </template>
    </ModalMenu>
  </div>
</template>

<script lang="ts" setup>
import { FeatureType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/market/v1/package_pb';
import dayjs from 'dayjs';
import dayJsDuration from 'dayjs/plugin/duration';
import { computed, ref } from 'vue';

import { hideBoosterPromoModal } from '@/components/booster-promo-modal/helpers/subscription-promo-helper/booster-promo-helper';
import SvgIcon from '@/components/inputs/icon/svg-icon/SvgIcon.vue';
import { hideModalSpinner, showModalSpinner } from '@/components/modal/helpers/modal/modal';
import ModalMenu from '@/components/modal/modal-menu/ModalMenu.vue';
import ModalSpinner from '@/components/modal/ModalSpinner.vue';
import { showNotification } from '@/components/notification/helpers/notification-shower/notification-shower';
import { isAllowedCreditsAction } from '@/components/payment/helpers/payment/payment';
import RetinaPhoto from '@/components/retina-image/RetinaPhoto.vue';
import { resolveCreditsLastAction } from '@/pages/payment/helpers/credits-last-action/credits-last-action';
import { useI18n } from '@/services/i18n';
import { CommonStateBoosterPromoModal } from '@/store/modules/common/common-state';
import { dispatch, getState } from '@/store/store-helper';

import { messages } from './i18n';

dayjs.extend(dayJsDuration);

const { t } = useI18n({ messages });

const isLoaderVisible = ref(false);

const duration = computed<string>(() => {
  const ms = promoModal.value.booster?.totalDurationMs ?? 0;
  const duration = dayjs.duration(ms);
  // Less then 1 hour.
  if (ms < 3600 * 1000) {
    return `${duration.asMinutes()} minutes`;
  }

  const hours = dayjs.duration(ms).asHours();
  if (hours === 1) {
    return `${hours} hour`;
  }
  return `${hours} hours`;
});

const promoModal = computed<CommonStateBoosterPromoModal>(() => {
  return getState().common.boosterPromoModal;
});

const isVisible = computed<boolean>(() => {
  return promoModal.value.isModalVisible;
});

const avatarId = computed<string>(() => {
  return getState().myProfile.short.avatar.id;
});

async function applyBooster() {
  const { booster } = promoModal.value;

  showModalSpinner();
  try {
    if (booster) {
      resolveCreditsLastAction('top');

      if (
        await isAllowedCreditsAction(
          Number(booster.priceCredits),
          // @TODO make types for `pkg.id` ( top-profile-plus & top-profile-new ).
          booster.id === 'top-profile-plus'
            ? FeatureType.TOP_PROFILE_PLUS
            : FeatureType.TOP_PROFILE,
          'my_profile',
        )
      ) {
        await dispatch('actionPaymentActivateBooster', booster);
        showNotification({
          type: 'booster-activated',
        });
      }
    }
  } finally {
    close();
    hideModalSpinner();
  }
}

function close(): void {
  hideBoosterPromoModal();
}
</script>
