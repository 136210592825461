import { I18n } from '@/services/i18n';

type Message = {
  terms: string;
  privacy: string;
  membership: string;
  accept: string;
  agree: string;
  and: string;
  antiScam: string;
  consentPolicy: string;
};

const messages: I18n<Message> = {
  en: {
    terms: ' Terms of Use & Service ',
    privacy: ' Privacy Policy ',
    membership: ' Membership and Refund Policy ',
    accept: ' I accept the',
    agree: ' I agree to the',
    and: 'and',
    antiScam: ' Safety, Anti-Human Trafficking, or Non-Solicitation',
    consentPolicy: ' Consent Policy',
  },
  es: {
    terms: ' Términos de Uso y Servicio ',
    privacy: ' Política de Privacidad ',
    membership: ' Política de Membresía y Reembolsos ',
    accept: ' Acepto los',
    agree: ' Estoy de acuerdo con los',
    and: 'y',
    antiScam: ' Seguridad, lucha contra la trata de personas o no captación de clientes',
    consentPolicy: ' Política de consentimiento',
  },
  de: {
    terms: ' Nutzungsbedingungen & Service ',
    privacy: ' Datenschutzrichtlinie ',
    membership: ' Mitgliedschafts- und Rückerstattungsrichtlinie ',
    accept: ' Ich akzeptiere die',
    agree: ' Ich stimme den',
    and: 'und',
    antiScam: ' Sicherheit, Anti-Menschenhandel oder Nicht-Solicitation',
    consentPolicy: ' Einwilligungserklärung',
  },
};

export { messages };
