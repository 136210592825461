export type IframeMessage = 'success' | 'fail';
export type IframeSubscriber = (message: IframeMessage) => void;

export function isInIframe(): boolean {
  return window.top !== window.self;
}

export function iframePostMessage(message: IframeMessage) {
  window.top?.postMessage(message, '*');
}

const subscribers: IframeSubscriber[] = [];
export function addIframeSubscriber(subscriber: IframeSubscriber) {
  subscribers.push(subscriber);
}

export function removeIframeSubscriber(subscriber: IframeSubscriber) {
  const index = subscribers.indexOf(subscriber);
  if (index !== -1) {
    subscribers.splice(index);
  }
}

let isListenerStarted = false;
export function startIframeMessageListener() {
  if (!isListenerStarted) {
    window.addEventListener('message', (e: MessageEvent) => {
      const message = e.data as IframeMessage;
      subscribers.forEach((subscriber) => {
        subscriber(message);
      });
    });
    isListenerStarted = true;
  }
}
