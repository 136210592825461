import { I18n } from '@/services/i18n';

type Message = {
  header: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'Failed to activate you subscription',
  },
  es: {
    header: 'No se pudo activar tu suscripción',
  },
  de: {
    header: 'Aktivierung Ihres Abonnements fehlgeschlagen',
  },
};

export { messages };
