import { I18n } from '@/services/i18n';

type Message = {
  match: string;
  chat: string;
};

const messages: I18n<Message> = {
  en: {
    match: 'There is a hit! Check it out.',
    chat: 'CHAT',
  },
  es: {
    match: '¡Hay un match! Échale un vistazo.',
    chat: 'CHAT',
  },
  de: {
    match: 'Es gibt einen Treffer! Schau es dir an.',
    chat: 'CHAT',
  },
};

export { messages };
