import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  description: string;
  action: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'Check astrological compatibility',
    description: 'This person might be your destiny. How about checking what the stars will say?',
    action: 'Check compatibility',
  },
  es: {
    header: 'Verifica compatibilidad astrológica',
    description:
      'Esta persona podría ser tu destino. ¿Qué tal si verificamos qué dicen las estrellas?',
    action: 'Verificar compatibilidad',
  },
  de: {
    header: 'Astrologische Kompatibilität prüfen',
    description: 'Diese Person könnte Ihr Schicksal sein. Wie wäre es, die Sterne zu befragen?',
    action: 'Kompatibilität prüfen',
  },
};

export { messages };
