import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  gotIt: string;
  credits: string;
  sendSomeone: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'are unused and will expire soon',
    gotIt: 'Got it',
    credits: 'credits',
    sendSomeone:
      'Send someone a gift, boost your profile, check Astrological or Romantic compatibility with someone.',
  },
  es: {
    header: 'están sin usar y expirarán pronto',
    gotIt: 'Entendido',
    credits: 'créditos',
    sendSomeone:
      'Envía un regalo a alguien, mejora tu perfil, verifica la compatibilidad astrológica o romántica con alguien.',
  },
  de: {
    header: 'sind ungenutzt und laufen bald ab',
    gotIt: 'Verstanden',
    credits: 'Credits',
    sendSomeone:
      'Senden Sie jemandem ein Geschenk, pushen Sie Ihr Profil, überprüfen Sie die astrologische oder romantische Kompatibilität mit jemandem.',
  },
};

export { messages };
