import { AccountStatus } from '@project-gd-x/dating-site-contracts/src/gen/gdx/common/v1/profile_pb';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import { showProlongationModal } from '@/components/prolongation-modal/helpers/prolongation/prolongation';
import { showDelayedTrialPromoModal } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/subscription-promo-helper';
import { getUserId, isUserLoggedIn } from '@/helpers/auth/auth';
import { loadFacebookScript } from '@/helpers/facebook-pixel/facebook-pixel';
import { googleAdwords } from '@/helpers/google-adwords/google-adwords';
import { initAnalytics } from '@/helpers/gtm-triggers/gtm-triggers';
import { resolveTrafficSource } from '@/helpers/marketing/marketing';
import { runSmartLook } from '@/helpers/smartlook/smartlook';
import { loadTiktokScript } from '@/helpers/tiktok-pixel/tiktok-pixel';
import { debounce } from '@/helpers/you-dont-need-lodash/you-dont-need-lodash';
import { resolveSubscriptionRenewBlockVisibility } from '@/pages/payment/components/purchase/components/purchase-promo/helpers/product-block/product-block';
import { handleConversionsThrottled } from '@/pages/payment/helpers/payment-websocket/helpers/conversion/conversion';
import { historyPaths, isSignupRoute, RouteName } from '@/router';
import { isNextRestricted } from '@/router/helpers/is-next-restricted/is-next-restricted';
import { getRedirectRouteFromRoot } from '@/router/helpers/redirect/redirect';
import { bq } from '@/services/bq/bq';
import { setLocaleFromLangCode } from '@/services/i18n';
import { sentryCaptureMessage } from '@/services/sentry/sentry';
import { connectToWebsocketServer } from '@/services/websocket';
import { getAndSaveIndexState } from '@/store/modules/common/common-helper/common-helper';
import { dispatch, getState } from '@/store/store-helper';

export function resolveRestrictedOrDeactivated(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
): void {
  const isRestricted = isNextRestricted(
    from.name as RouteName,
    to.matched[to.matched.length - 1]?.name as RouteName,
  );

  if (isRestricted) {
    next(false);
  } else if (isDeactivatedAccountNeedsRedirect(to)) {
    next({ name: 'myProfileDeactivated' } as { name: RouteName });
  } else {
    next();
  }
}

export function isDeactivatedAccountNeedsRedirect(to: RouteLocationNormalized) {
  return (
    getState().common.index.status === AccountStatus.DEACTIVATED &&
    (to.name as RouteName) !== 'myProfileDeactivated' &&
    !to.meta.isGuest
  );
}

export function isHistoryItem(to: RouteLocationNormalized): boolean {
  // Skip root path from history, in order to not be able to go back to the root page
  return to.fullPath !== '/' && !to.meta.isGuest && !to.meta.isHistorySkip && !isSignupRoute(to);
}

export function addHistoryItem(to: RouteLocationNormalized): void {
  if (isHistoryItem(to)) {
    const lastPath = historyPaths.slice(-1)[0];
    // Last history item should be deduped.
    if (lastPath !== to.path) {
      historyPaths.push(to.path);
    }
  }
}

export function addDocumentTitle(to: RouteLocationNormalized): void {
  document.title = (
    to.path === '/' ? to.meta.title : import.meta.env.VUE_APP_PROJECT_NAME
  ) as string;
}

export function addRouteMeta(to: RouteLocationNormalized): void {
  try {
    const meta = document.querySelector('meta[name="description"]') as HTMLMetaElement;
    const linkCanonical = document.querySelector('link[rel="canonical"]') as HTMLMetaElement;
    meta.setAttribute('content', to.path === '/' ? (to.meta.metaDescription as string) : '');
    linkCanonical.setAttribute(
      'href',
      'https://' + import.meta.env.VUE_APP_PROJECT_HOST + to.path.replace(/\/$/, ''),
    );
  } catch (error) {
    sentryCaptureMessage({
      message: 'Failed to assign description content to meta tag on homepage',
      captureContext: { extra: { error } },
    });
  }
}

export async function goNextIfDefaultPath(next: NavigationGuardNext): Promise<void> {
  if (!isUserLoggedIn()) {
    return next();
  }

  next(getRedirectRouteFromRoot());
}

const updateRouteVisitedDebounced = debounce((pathFull: string) => {
  dispatch('actionCommonStoreClientEventRouteVisited', pathFull);
}, 5000);

export async function goNextIfUserIsNotGuest(to: RouteLocationNormalized): Promise<void> {
  if (to.meta.canBeSaved && to.path !== '/') {
    updateRouteVisitedDebounced(to.fullPath);
  }

  await getAndSaveIndexState();

  setLocaleFromLangCode(getState().common.index.interfaceLanguage);

  connectToWebsocketServer();

  const landingUrl = getState().common.index.landingUrl;
  resolveTrafficSource(
    {
      facebook() {
        loadFacebookScript(landingUrl);
      },
      tiktok() {
        loadTiktokScript();
      },
      googleAdw() {
        googleAdwords.init();
      },
    },
    {
      landingUrl,
    },
  );

  initAnalytics();
  bq.init(getUserId());

  // Do not show promo trial during onboarding
  if (!isSignupRoute(to)) {
    showDelayedTrialPromoModal();
  }

  resolveSubscriptionRenewBlockVisibility();

  const afterPaymentPage = to.name === 'paymentSuccess' || to.name === 'paymentFail';
  if (!afterPaymentPage) {
    dispatch('actionPaymentGetSubscriptionStatus').then((subscription) => {
      showProlongationModal({
        hasTimeRestriction: true,
        subscription,
      });
    });
  }

  runSmartLook();

  // amplitude.init({
  //   userId: getUserId(),
  // });

  handleConversionsThrottled();
}

export function resolveWebPushLogic(to: RouteLocationNormalized): void {
  // If we came from `webpush` click we'll have `?utm_source=webpush` in url.
  if (to?.query?.utm_source === 'webpush') {
    const webPushType = to.query?.utm_medium ?? '';

    dispatch('actionCommonStoreClientEventWebPushClick', webPushType as string);
  }
}

// export function resolveSignUpNavigation(
//   to: RouteLocationNormalized,
//   next: NavigationGuardNext,
// ): void {
//   if (to.path.includes('/signup')) {
//     if (isSearchPageVisited()) {
//       next({ name: 'search' as RouteName, replace: true });
//     }
//   }
// }
