import { I18n } from '@/services/i18n';

type Message = {
  charge: string;
  body: string;
  visit: string;
  policy: string;
  details: string;
};

const messages: I18n<Message> = {
  en: {
    charge: 'You will be charged',
    body: 'You can cancel your trial anytime you want in account settings. By tapping {btnText}, you agree that if you don’t cancel at least 24 hours before the end of the 7-day introductory period, you will automatically be charged the total price of {priceNext} monthly. To cancel subscription, write to ',
    visit: 'Please visit our ',
    policy: 'Membership and Refund Policy',
    details:
      'for more details. Also, you can manage your subscription in your account/profile settings',
  },
  es: {
    charge: 'Se te cobrará',
    body: 'Puedes cancelar tu prueba en cualquier momento en la configuración de tu cuenta. Al tocar {btnText}, aceptas que si no cancelas al menos 24 horas antes del final del período introductorio de 7 días, se te cobrará automáticamente el precio total de {priceNext} mensualmente. Para cancelar la suscripción, escribe a ',
    visit: 'Visita nuestro ',
    policy: 'Política de Membresía y Reembolso',
    details:
      'para más detalles. Además, puedes gestionar tu suscripción en la configuración de tu cuenta/perfil',
  },
  de: {
    charge: 'Sie werden belastet',
    body: 'Sie können Ihre Testversion jederzeit in den Kontoeinstellungen kündigen. Durch das Tippen von {btnText} stimmen Sie zu, dass, wenn Sie nicht mindestens 24 Stunden vor Ende des 7-tägigen Einführungszeitraums kündigen, Ihnen der Gesamtpreis von {priceNext} monatlich berechnet wird. Um das Abonnement zu kündigen, schreiben Sie an ',
    visit: 'Bitte besuchen Sie unsere ',
    policy: 'Mitgliedschafts- und Rückerstattungsrichtlinie',
    details:
      'für weitere Details. Sie können Ihr Abonnement auch in Ihren Konto-/Profileinstellungen verwalten',
  },
};

export { messages };
