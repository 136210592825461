import { I18n } from '@/services/i18n';

type Message = {
  thereIsHit: string;
  sayHello: string;
  likedEachOther: string;
};

const messages: I18n<Message> = {
  en: {
    thereIsHit: 'There is a hit!',
    sayHello: 'Say “Hello”',
    likedEachOther: 'You and {name} liked each other. Sending a gift is a perfect ice-breaker',
  },
  es: {
    thereIsHit: '¡Hay un match!',
    sayHello: 'Di “Hola”',
    likedEachOther:
      'Tú y {name} se gustaron mutuamente. Enviar un regalo es un excelente rompehielos',
  },
  de: {
    thereIsHit: 'Es gibt einen Treffer!',
    sayHello: 'Sagen Sie “Hallo”',
    likedEachOther:
      'Sie und {name} haben sich gegenseitig gefallen. Ein Geschenk zu senden ist ein perfekter Eisbrecher',
  },
};

export { messages };
