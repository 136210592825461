// Documentation https://developers.freshchat.com/web-sdk/#intro
import { getUserId, isUserLoggedIn } from '@/helpers/auth/auth';
import { getLocale, Locale } from '@/services/i18n';
import { dispatch } from '@/store/store-helper';

declare global {
  interface Window {
    fcWidget: {
      (fn: () => void): void;
      close: () => void;
      destroy: () => void;
      open: () => void;
      show: () => void;
      hide: () => void;
      init: (...args: unknown[]) => void;
      isOpen: () => boolean;
      isInitialized: () => boolean;
      isLoaded: () => boolean;
      setConfig: (x: unknown) => boolean;
      user: {
        setFirstName: (param: string) => void;
        setEmail: (param: string) => void;
        // sdk doesn't have field for id, so we use setPhoneCountryCode for collecting user id
        setPhoneCountryCode: (param: string) => void;
      };
    };
  }
}

// export type WidgetPosition = 'pos-bottom' | 'pos-top';

export function openWidget(): Promise<void> {
  return initWidget().then(() => {
    if (!window.fcWidget.isOpen()) {
      window.fcWidget.open();
    }
  });
}

export function isWidgetOpen() {
  return window.fcWidget?.isOpen();
}

// export function closeWidget(): Promise<void> {
//   return loadedPromise?.then(() => {
//     if (window.fcWidget.isOpen()) {
//       window.fcWidget.close();
//     }
//   });
// }

// export function showWidgetButton(position: WidgetPosition = 'pos-bottom'): Promise<void> {
//   return loadedPromise?.then(() => {
//     window.fcWidget.setConfig({
//       headerProperty: {
//         hideChatButton: false,
//       },
//     });
//     // positionClasses[position]
//     const elt = document.getElementById('fc_frame');
//     if (elt) {
//       const classes = Array.from(elt.classList);
//       classes.forEach((cl) => {
//         const c = cl as WidgetPosition;
//         if (c === 'pos-bottom' || c === 'pos-top') {
//           elt.classList.remove(c);
//         }
//       });
//       elt.classList.add(position);
//     }
//   });
// }

// export function hideWidgetButton(): Promise<void> {
//   return loadedPromise?.then(() => {
//     window.fcWidget.setConfig({
//       headerProperty: {
//         hideChatButton: true,
//       },
//     });
//   });
// }

function getWidgetLocale(): string {
  const locale = getLocale();

  switch (locale) {
    case Locale.Es:
      return 'es-LA';

    default:
      return 'en';
  }
}

function initWidget() {
  return loadWidgetScript().then(async () => {
    // initWidget();

    // window.fcWidget.destroy();
    window.fcWidget.init({
      token: '390ec6f4-3f5d-413e-adf0-fc7b08de6b32',

      host: 'https://wchat.eu.freshchat.com',

      locale: getWidgetLocale(),

      externalId: getUserId(),

      config: {
        headerProperty: {
          // Hide the chat button on load
          hideChatButton: true,
          fontName: 'Poppins',
        },
        cssNames: {
          widget: 'widget_wrap',
        },
      },
    });

    if (isUserLoggedIn()) {
      await setDataIntoWidget();
    }
  });
}

async function setDataIntoWidget(): Promise<void> {
  const data = await dispatch('actionSettingsGetAccount');
  const user = window.fcWidget?.user;

  if (user) {
    user.setEmail(data.email);
    user.setFirstName(data.name);
    // sdk doesn't have field for id, so we use setPhoneCountryCode for collecting user id
    user.setPhoneCountryCode(data.id);
  }
}

let loadedPromise: Promise<void>;

function loadWidgetScript() {
  if (!loadedPromise) {
    loadedPromise = new Promise((resolve) => {
      const script = document.createElement('script');
      script.setAttribute('id', 'Freshchat-js-sdk');
      script.setAttribute('async', 'async');
      script.setAttribute('src', 'https://wchat.eu.freshchat.com/js/widget.js');
      script.addEventListener('load', () => {
        resolve();
      });
      document.head.appendChild(script);
    });
  }

  return loadedPromise;
}

// Freshdesk script can't be loaded in test env, so we have to disable it.
// if (import.meta.env.MODE !== 'test') {
//   loadWidgetScript().then(() => {
//     initWidget();
//   });
// }
