import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';
import { ChatStateDialogMessageContent } from '@/pages/chat/store/chat-state';
import { sentryCaptureMessage } from '@/services/sentry/sentry';
import { commit, dispatch } from '@/store/store-helper';

export function useChatFooter() {
  async function sendTextMessage({
    messageText,
    dialogId,
  }: {
    messageText: string;
    dialogId: string;
  }): Promise<void> {
    commit('mutationChatAddDraftsMessage', {
      dialogId,
      message: messageText,
    });

    await sendMessage({
      content: {
        type: 'text',
        text: messageText,
      },
      dialogId,
    });

    customEventTrigger({
      event: 'chat-send-text-message',
    });
  }

  async function sendMessage({
    content,
    dialogId,
  }: {
    content: ChatStateDialogMessageContent;
    dialogId: string;
  }): Promise<void> {
    try {
      await dispatch('actionChatSendMessage', {
        chatId: dialogId,
        content,
      });
    } catch (error) {
      sentryCaptureMessage({
        message: `Failed to send ${content.type} message`,
        captureContext: { extra: { error } },
      });
    }

    commit('mutationChatDecrementFreeMessagesCount', dialogId);
  }

  return {
    sendTextMessage,
  };
}
