import { I18n } from '@/services/i18n';

type Message = {
  type: string;
  hintIce: string;
  hintGift: string;
};

const messages: I18n<Message> = {
  en: {
    type: 'Type a message...',
    hintIce: 'Not sure what to say?',
    hintGift: 'How about sending a gift?',
  },
  es: {
    type: 'Escribe un mensaje...',
    hintIce: '¿No sabes qué decir?',
    hintGift: '¿Qué tal enviar un regalo?',
  },
  de: {
    type: 'Nachricht eingeben...',
    hintIce: 'Nicht sicher, was Sie schreiben können?',
    hintGift: 'Wie wäre es mit einem Geschenk?',
  },
};

export { messages };
