import { reactive } from 'vue';

import { usePromoTimer } from '@/hooks/use-promo-timer/use-promo-timer';
import { getState } from '@/store/store-helper';

export function useSelf() {
  // Ref -----------------------------------------------------------------------
  const { isOrganic } = getState().common.index;

  const TIMER_DURATION_SECONDS = 600;
  const TIMER_DURATION_RESTART_SECONDS = -1200;
  // Hook ----------------------------------------------------------------------

  const promoTimer = usePromoTimer({
    durationSec: TIMER_DURATION_SECONDS,
    durationRestartSec: TIMER_DURATION_RESTART_SECONDS,
    timerName: 'ntf',
  });
  // Computed ------------------------------------------------------------------

  // Method --------------------------------------------------------------------

  function init() {
    promoTimer.init();
  }

  function destroy() {
    promoTimer.destroy();
  }

  return reactive({
    init,
    isOrganic,
    promoTimer,
    destroy,
  });
}
