import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  description: string;
  action: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'Have you met {userName}?',
    description: 'You have some things in common. How about paying a visit to their profile?',
    action: 'Meet {userName}',
  },
  es: {
    header: '¿Has conocido a {userName}?',
    description: 'Tienen algunas cosas en común. ¿Qué tal una visita a su perfil?',
    action: 'Conoce a {userName}',
  },
  de: {
    header: 'Haben Sie {userName} kennengelernt?',
    description:
      'Sie haben einige gemeinsame Interessen. Wie wäre es mit einem Besuch ihres Profils?',
    action: 'Treffen Sie {userName}',
  },
};

export { messages };
