<template>
  <ModalPortal :background-scroll="false">
    <div class="modal-dialog modal-dialog-full h-full modal_match">
      <div class="modal-content h-full">
        <div class="modal-body text-center flex flex-col" data-test-id="match_popup">
          <div class="modal_match-header">
            <Close @click="close"></Close>
          </div>

          <div class="mt-auto mb-auto">
            <div class="modal_match-title" data-test-id="match_popup_title">
              {{ t('title') }}
            </div>
            <div class="modal_match-img_group" data-test-id="match_popup_photos">
              <div class="modal_match-icon">
                <i class="icon icon-like_solid"></i>
              </div>

              <div class="modal_match-img modal_match-img_my">
                <RetinaPhoto
                  :photo-id="match.myAvatar?.id ?? ''"
                  :width="120"
                  :height="120"
                  img-width="100%"
                  alt="user's photo"
                ></RetinaPhoto>
              </div>
              <div class="modal_match-img modal_match-img_friend">
                <RetinaPhoto
                  :photo-id="match.avatar?.id ?? ''"
                  :width="120"
                  :height="120"
                  img-width="100%"
                  alt="user's photo"
                ></RetinaPhoto>
              </div>
            </div>
            <ChatFooterIcebreaker
              class="match-ice-breaker !block rounded-lg max-w-[312px] mx-auto"
              data-test-id="match_popup_ice-breakers"
              :ice-breakers="chatIceBreakers.iceBreakers"
              @select="applyIceBreaker"
            />
          </div>

          <ChatFooter
            v-if="chatGifts.gifts.length && chatIceBreakers.iceBreakers.length"
            ref="footerRef"
            v-model:tooltip-hint="hint"
            :dialog-user="dialogUser"
            :gifts="chatGifts.gifts"
            :ice-breakers="chatIceBreakers.iceBreakers"
            :dialog-id="match.dialogId"
            :is-hint-visible="false"
            @send-message="goChatPage"
          />
        </div>
      </div>
    </div>
  </ModalPortal>
</template>

<script lang="ts" setup>
import { PropType, reactive } from 'vue';

import Close from '@/components/layout/components/header/components/close/Close.vue';
import { useMatchChat } from '@/components/match-modal/hooks/use-match-chat/use-match-chat';
import { useMatchDialogUser } from '@/components/match-modal/hooks/use-match-dialog-user/use-match-dialog-user';
import { useMatchNavigation } from '@/components/match-modal/hooks/use-match-navigation/use-match-navigation';
import { useMatchTooltipHint } from '@/components/match-modal/hooks/use-match-tooltip-hint/use-match-tooltip-hint';
import ModalPortal from '@/components/modal/ModalPortal.vue';
import RetinaPhoto from '@/components/retina-image/RetinaPhoto.vue';
import { MatchStateNotification } from '@/hooks/use-match/use-match';
import ChatFooter from '@/pages/chat/components/chat-view/components/chat-footer/ChatFooter.vue';
import ChatFooterIcebreaker from '@/pages/chat/components/chat-view/components/chat-footer/components/chat-footer-icebreaker/ChatFooterIcebreaker.vue';
import { useChatFooter } from '@/pages/chat/components/chat-view/components/chat-footer/hooks/use-chat-footer/use-chat-footer';
import { useStoreChatGifts } from '@/pages/chat/store/hooks/use-store-chat-gifts/use-store-chat-gifts';
import { useStoreChatIceBreakers } from '@/pages/chat/store/hooks/use-store-chat-ice-breakers/use-store-chat-ice-breakers';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const props = defineProps({
  match: {
    required: true,
    type: Object as PropType<MatchStateNotification>,
  },
});

const emit = defineEmits(['close']);

const { t } = useI18n({ messages });

const chatIceBreakers = reactive(useStoreChatIceBreakers(props.match.userId));

function applyIceBreaker(icebreaker: string): void {
  sendTextMessage({
    messageText: icebreaker,
    dialogId: props.match.dialogId,
  });
  goChatPage();
  close();
}

function startMatchModalLogics(): void {
  chatIceBreakers.load();
}

const { sendTextMessage } = useChatFooter();

const { dialogUser } = useMatchDialogUser(props.match);

const { close, goChatPage } = useMatchNavigation(props.match.dialogId, emit);

const { hint } = useMatchTooltipHint();

const { footerRef } = useMatchChat({
  goChatPage,
});
const chatGifts = reactive(useStoreChatGifts());
chatGifts.load();

startMatchModalLogics();
</script>

<style lang="scss" src="./style.scss"></style>
