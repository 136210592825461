import { CaptureContext, SeverityLevel } from '@sentry/types';
import {
  init,
  captureMessage,
  BrowserTracing,
  getCurrentScope,
  vueRouterInstrumentation,
} from '@sentry/vue';
import { Vue } from '@sentry/vue/types/types';
import { Router } from 'vue-router';

import { isLocalhost } from '@/helpers/location/location';
import { getCache } from '@/helpers/persistent-cache';
import { i18n } from '@/services/i18n';

// Errors percent to track ( 1 means 100% )
const SAMPLE_RATE = 0.2;
const TRACES_SAMPLE_RATE = 0.01;

export function setSentryUserId(userId: string): void {
  // configureScope((scope) => {
  //   scope.setUser({
  //     id: userId,
  //   });
  // });

  getCurrentScope().setUser({
    id: userId,
  });
}

export function setSentryLocale(locale: string): void {
  // configureScope((scope) => {
  //   scope.setTag('locale', locale);
  // });

  getCurrentScope().setTag('locale', locale);
}

let isSentryInited = false;
export function initSentry(app: Vue, router: Router): void {
  // Enable Sentry only on production.
  if (import.meta.env.MODE !== 'production') {
    return;
  }

  if (!isSentryInited) {
    isSentryInited = true;
    init({
      app,
      attachProps: true,
      logErrors: true,
      dsn: 'https://173f9134338347038177429491d7ad92@o446664.ingest.sentry.io/5425486',
      sampleRate: SAMPLE_RATE,
      debug: false,
      release: import.meta.env.VUE_APP_BUILD_VERSION,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: vueRouterInstrumentation(router),
        }),
      ],
      tracesSampleRate: TRACES_SAMPLE_RATE,

      // All patterns are form here https://gist.github.com/impressiver/5092952
      // More here https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
      ignoreErrors: [
        'adsbygoogle',
        'top.GLOBALS',
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        'fb_xd_fragment',
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        'conduitPage',
        'Script error.',
        // 'Non-Error promise rejection captured with keys: code, message, metadata',
        // 'Non-Error exception captured with keys: code, message, metadata',
        // Simple vue-router warning, it's okay to be fired during navigation redirects
        'Navigation cancelled from',
        'Such email is already registered',

        // Error response from backend, is not frontend error case
        'keys: code, message, metadata',
        'RpcError',
        'ConnectError',
        'unregistered token: bad credentials',
        '[unauthenticated] unauthenticated',

        // Promise rejection on iOS & safari
        'TypeError: Load failed',
        'TypeError: Importing a module script failed',

        // Error from freshdesk widget, nothing we can do about it.
        // https://gdx-dating.sentry.io/issues/4004604166/?project=542548
        '_li_ss',

        // Unknown error from Sentry
        'TypeError: Failed to fetch',

        // https://github.com/The-Best-Codes/bestcodes-react-site/issues/65
        // https://issuetracker.google.com/issues/396043331?pli=1
        "ReferenceError: Can't find variable: gmo",
      ],
      denyUrls: [
        /graph\.facebook\.com/i,
        /connect\.facebook\.net\/en_US\/all\.js/i,
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        /extensions\//i,
        /^chrome:\/\//i,
        /127\.0\.0\.1:4001\/isrunning/i,
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
    });

    setSentryLocale(i18n.global.locale);
    const userId = getCache('user_id');
    if (userId) {
      setSentryUserId(userId);
    }
  }
}

export function sentryCaptureMessage({
  message,
  captureContext,
}: {
  message: string;
  captureContext?: CaptureContext | SeverityLevel;
}): void {
  if (import.meta.env.MODE !== 'test') {
    if (isLocalhost()) {
      console.warn(message, captureContext);
    } else {
      if (isSentryInited) {
        captureMessage(message, captureContext);
      }
    }
  }
}
