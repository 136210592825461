import { I18n } from '@/services/i18n';

type Message = {
  submit: string;
};

const messages: I18n<Message> = {
  en: {
    submit: 'Submit',
  },
  es: {
    submit: 'Enviar',
  },
  de: {
    submit: 'Absenden',
  },
};

export { messages };
