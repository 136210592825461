import { I18n } from '@/services/i18n';

type Message = {
  youRunOutOfMessages: string;
  trial: string;
  premium: string;
  upgradeYourSubscriptionChat: string;
  youRunOutOfLikes: string;
  upgradeYourSubscriptionLikes: string;
};

const messages: I18n<Message> = {
  en: {
    youRunOutOfMessages: 'You run out of messages',
    trial: 'Try trial',
    premium: 'Upgrade',
    upgradeYourSubscriptionChat: 'Upgrade your subscription to reply to your match and chat',
    youRunOutOfLikes: 'You run out of likes',
    upgradeYourSubscriptionLikes:
      'Upgrade your subscription to send unlimited likes to other people',
  },
  es: {
    youRunOutOfMessages: 'Te has quedado sin mensajes',
    trial: 'Prueba la prueba',
    premium: 'Mejora',
    upgradeYourSubscriptionChat: 'Mejora tu suscripción para responder a tu match y chatear',
    youRunOutOfLikes: 'Te has quedado sin me gusta',
    upgradeYourSubscriptionLikes:
      'Mejora tu suscripción para enviar me gusta ilimitados a otras personas',
  },
  de: {
    youRunOutOfMessages: 'Sie haben keine Nachrichten mehr',
    trial: 'Testversion ausprobieren',
    premium: 'Upgrade',
    upgradeYourSubscriptionChat:
      'Aktualisieren Sie Ihr Abonnement, um auf Ihre Übereinstimmung zu antworten und zu chatten',
    youRunOutOfLikes: 'Ihnen gehen die Likes aus',
    upgradeYourSubscriptionLikes:
      'Aktualisieren Sie Ihr Abonnement, um unbegrenzt Likes an andere Personen zu senden',
  },
};

export { messages };
