<template>
  <div class="trustpilot-prereview" data-test-id="trustpilot_prereview_popup">
    <h1 class="text-dark text-button1 font-semibold text-center">{{ t('header') }}</h1>
    <div class="flex gap-3 w-60 mx-10 my-7">
      <div
        :class="{ selected: !self.rating || self.rating === 'poor', bg: self.rating === 'poor' }"
        class="trustpilot-prereview-item"
        data-test-id="answer_btn"
        @click="self.setRating('poor')"
      >
        <img src="./assets/img/1.png" alt="poor" />
        {{ t('poor') }}
      </div>
      <div
        :class="{ selected: !self.rating || self.rating === 'great', bg: self.rating === 'great' }"
        class="trustpilot-prereview-item"
        data-test-id="answer_btn"
        @click="self.setRating('great')"
      >
        <img src="./assets/img/2.png" alt="great" />
        {{ t('great') }}
      </div>
      <div
        :class="{ selected: !self.rating || self.rating === 'wow', bg: self.rating === 'wow' }"
        class="trustpilot-prereview-item"
        data-test-id="answer_btn"
        @click="self.setRating('wow')"
      >
        <img src="./assets/img/3.png" alt="wow" />
        {{ t('wow') }}
      </div>
    </div>

    <div class="border-t border-dark-500"></div>

    <PositiveModalMenuButton v-if="self.rating" @click="emit('rating-updated', self.rating)">
      {{ t('submit') }}
    </PositiveModalMenuButton>
    <NeutralModalMenuButton v-else @click="trustpilotModal.hide()">
      {{ t('notNow') }}
    </NeutralModalMenuButton>
  </div>
</template>

<script lang="ts" setup>
import NeutralModalMenuButton from '@/components/modal/modal-menu/components/NeutralModalMenuButton.vue';
import PositiveModalMenuButton from '@/components/modal/modal-menu/components/PositiveModalMenuButton.vue';
import { trustpilotModal } from '@/components/trustpilot-modal/helpers/trustpilot-modal/trustpilot-modal';
import { useI18n } from '@/services/i18n';

import { Rating, useSelf } from './hooks/use-self/use-self';
import { messages } from './i18n';
const { t } = useI18n({ messages });

const emit = defineEmits({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  'rating-updated': (rating: Rating) => true,
});

const self = useSelf({});

self.init();
</script>

<style lang="scss" scoped>
.trustpilot-prereview {
  &-item {
    @apply text-dark-200 text-tag text-center cursor-pointer p-2 rounded-l;

    img {
      @apply w-14 opacity-30 mb-2 transition duration-150 ease-out;
    }

    &:hover {
      @apply bg-dark-500;
    }
  }

  &-item.selected {
    @apply text-dark-100;

    img {
      @apply opacity-100 scale-110;
    }
  }

  &-item.bg {
    @apply bg-dark-500 cursor-default;
  }
}
</style>
