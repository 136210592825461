import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  modalText: string;
  ok: string;
  cancel: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'Don’t miss a hit!',
    modalText: 'Get notified when {userName} is online, likes or messages you.',
    ok: 'OK',
    cancel: 'Cancel',
  },
  es: {
    header: '¡No te pierdas un golpe!',
    modalText:
      'Recibe una notificación cuando {userName} esté en línea, le gustes o te envíe un mensaje.',
    ok: 'OK',
    cancel: 'Cancelar',
  },
  de: {
    header: 'Verpassen Sie keinen Treffer!',
    modalText:
      'Erhalten Sie Benachrichtigungen, wenn {userName} online ist, Sie mag oder Ihnen Nachrichten sendet.',
    ok: 'OK',
    cancel: 'Abbrechen',
  },
};

export { messages };
