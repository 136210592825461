import { I18n } from '@/services/i18n';

type Message = {
  paySafe: string;
  hint: string;
  policy: string;
  getPremium: string;
  activate: string;
};

const messages: I18n<Message> = {
  en: {
    paySafe: 'Pay safe and secure',
    hint: 'By clicking “GET -50% PREMIUM” you agree to enroll in a {label} subscription to https://meetmyage.com/ service. The {label} trial costs {packageSelectedPriceFormatted}. If you don’t cancel during your {label} trial, your subscription will automatically renew until you cancel and you will be billed {packageSelectedPriceNextFormatted} every {label}. Payments will be charged from the card you specified here. You can cancel your subscription by contacting our customer support team via email at',
    policy: 'Membership and Refund Policy',
    getPremium: 'GET -50% PREMIUM',
    activate: 'Activate Premium',
  },
  es: {
    paySafe: 'Pague de forma segura y protegida',
    hint: 'Al hacer clic en “OBTENER -50% PREMIUM” acepta inscribirse en una suscripción {label} al servicio de https://meetmyage.com/. La prueba {label} cuesta {packageSelectedPriceFormatted}. Si no cancela durante su prueba {label}, su suscripción se renovará automáticamente hasta que cancele y se le facturará {packageSelectedPriceNextFormatted} cada {label}. Los pagos se cargarán de la tarjeta que especificó aquí. Puede cancelar su suscripción poniéndose en contacto con nuestro equipo de atención al cliente por correo electrónico a',
    policy: 'Política de Membresía y Reembolso',
    getPremium: 'OBTENER -50% PREMIUM',
    activate: 'Activate Premium',
  },
  de: {
    paySafe: 'Sicher und geschützt zahlen',
    hint: 'Durch Klicken auf “GET -50% PREMIUM” stimmen Sie der Einschreibung in ein {label} Abonnement auf https://meetmyage.com/ zu. Der {label} Test kostet {packageSelectedPriceFormatted}. Wenn Sie während Ihres {label} Tests nicht kündigen, wird Ihr Abonnement automatisch verlängert, bis Sie kündigen und Ihnen wird {packageSelectedPriceNextFormatted} alle {label} berechnet. Zahlungen werden von der Karte abgebucht, die Sie hier hinterlegt haben. Sie können Ihr Abonnement kündigen, indem Sie unser Kundenservice-Team per E-Mail kontaktieren unter',
    policy: 'Mitgliedschafts- und Rückerstattungsrichtlinie',
    getPremium: 'GET -50% PREMIUM',
    activate: 'Premium aktivieren',
  },
};

export { messages };
