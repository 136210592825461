import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  description: string;
  action: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'Romantic compatibility with...',
    description: '{name} checked their romantic compatibility with you',
    action: 'Meet {name}',
  },
  es: {
    header: 'Compatibilidad romántica con...',
    description: '{name} verificó su compatibilidad romántica contigo',
    action: 'Conoce a {name}',
  },
  de: {
    header: 'Romantische Kompatibilität mit...',
    description: '{name} hat ihre romantische Kompatibilität mit Ihnen überprüft',
    action: 'Treffen Sie {name}',
  },
};

export { messages };
