import { I18n } from '@/services/i18n';

type Message = {
  rewardClaimed: string;
};

const messages: I18n<Message> = {
  en: {
    rewardClaimed: 'Reward claimed',
  },
  es: {
    rewardClaimed: 'Recompensa reclamada',
  },
  de: {
    rewardClaimed: 'Belohnung beansprucht',
  },
};

export { messages };
