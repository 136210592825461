import { I18n } from '@/services/i18n';

type Message = {
  text: string;
};

const messages: I18n<Message> = {
  en: {
    text: 'Your account is active',
  },
  es: {
    text: 'Tu cuenta está activa',
  },
  de: {
    text: 'Ihr Konto ist aktiv',
  },
};

export { messages };
