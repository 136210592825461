<template>
  <div class="modal-popover explanatory d-block" @click="close">
    <div class="modal_default-dialog">
      <div class="modal_default-content">
        <div class="modal_default-body">
          <div class="modal_default-trial">
            <div class="explanatory-avatar_group flex justify-center">
              <div class="rounded_img rounded_img_avatar">
                <RetinaPhoto
                  v-if="profile"
                  class="rounded-full"
                  :photo-id="profile.avatar.id"
                  :width="64"
                  :height="64"
                />
              </div>
              <div class="rounded_img rounded_img_coin">
                <SvgIcon name="coin" />
              </div>
            </div>
            <div class="explanatory-title text-center">
              {{ t('youRunOutOfCredits') }}
            </div>
            <div class="explanatory-txt color-gray-1 text-center text-tag font-medium">
              {{ t('getMoreCredits') }}
            </div>
            <div class="explanatory-btn explanatory-btn_credits flex justify-center">
              <button class="btn btn-primary mb-0" @click.stop="action">
                {{ t('credits') }}
              </button>
            </div>
            <PurchaseAutoTopUp
              v-if="!initialAutoTopUpValue"
              :auto-refill-value="true"
              @mounted="setInitialAutoTopUp"
              @click.stop
            >
              <template #auto-refill-text>{{ t('automaticallyTopUpMyCredits') }}</template>
            </PurchaseAutoTopUp>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onUnmounted, ref } from 'vue';

import SvgIcon from '@/components/inputs/icon/svg-icon/SvgIcon.vue';
import RetinaPhoto from '@/components/retina-image/RetinaPhoto.vue';
import { MyProfileStateShort } from '@/pages/my-profile/store/my-profile-state';
import PurchaseAutoTopUp from '@/pages/payment/components/layout/components/purchase-auto-top-up/PurchaseAutoTopUp.vue';
import { pushRoute } from '@/router';
import { useI18n } from '@/services/i18n';
import { dispatch, getStore } from '@/store/store-helper';

import { messages } from './i18n';

const shouldRemoveAutoTopUp = ref(false);
const initialAutoTopUpValue = ref(false);

const emit = defineEmits(['close', 'action']);

const { t } = useI18n({ messages });

const profile = computed<MyProfileStateShort>(() => {
  return getStore().state.myProfile.short;
});

function setInitialAutoTopUp(value: boolean): void {
  initialAutoTopUpValue.value = value;
}

function close(): void {
  shouldRemoveAutoTopUp.value = true;
  emit('close');
}

function action(): void {
  pushRoute('paymentPurchaseCredits', {
    source: 'out-of-credits-modal',
  });
  emit('close');
}

onUnmounted((): void => {
  if (shouldRemoveAutoTopUp.value && !initialAutoTopUpValue.value) {
    dispatch('actionPaymentSetCreditsAutorefill', false);
  }
});
</script>

<style lang="scss" scoped src="./style.scss"></style>
