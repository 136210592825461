import { I18n } from '@/services/i18n';

type Message = {
  free: string;
  basic: string;
  premium: string;
  'lovely-and-sweet': string;
  'merry-christmas': string;
  'ice-breakers': string;
  hobby: string;
  new: string;
};

const messages: I18n<Message> = {
  en: {
    free: 'free',
    basic: 'Basic',
    premium: 'Premium',
    'merry-christmas': 'Merry Christmas',
    'lovely-and-sweet': 'Lovely and Sweet',
    'ice-breakers': 'Ice breakers',
    hobby: 'Hobby',
    new: 'New',
  },
  es: {
    free: 'gratis',
    basic: 'Básico',
    premium: 'Premium',
    'merry-christmas': 'Feliz Navidad',
    'lovely-and-sweet': 'Lindo y dulce',
    'ice-breakers': 'Rompehielos',
    hobby: 'Pasatiempo',
    new: 'Nuevo',
  },
  de: {
    free: 'kostenlos',
    basic: 'Basic',
    premium: 'Premium',
    'merry-christmas': 'Frohe Weihnachten',
    'lovely-and-sweet': 'Lieblich und süß',
    'ice-breakers': 'Eisbrecher',
    hobby: 'Hobby',
    new: 'Neu',
  },
};

export { messages };
