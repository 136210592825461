export function scrollTo(y: number) {
  window.scrollTo(0, y);
}

export function scrollTop() {
  scrollTo(0);
}

export function scrollBottom() {
  scrollTo(9999999);
}

export function disableBodyScroll() {
  document.body.style.width = '100%';
  document.body.style.overflow = 'hidden';
}

export function enableBodyScroll() {
  document.body.style.width = '';
  document.body.style.overflow = '';
}

let searchScrollYPosition = 0;
export function getSearchScrollYPosition() {
  return searchScrollYPosition;
}

export function setSearchScrollYPosition(y: number) {
  searchScrollYPosition = y;
}

export function scrollIntoView(elId: string) {
  const element = document.getElementById(elId) as HTMLElement;
  if (!element) {
    console.warn(`Element with ID "${elId}" not found, cannot scroll into view.`);
    return;
  }
  element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
}
