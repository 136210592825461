import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  rules: string;
  policy: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'You will be charged',
    rules: 'Our rules on refunds are provided in the',
    policy: 'Membership and Refund Policy',
  },
  es: {
    header: 'Se le cobrará',
    rules: 'Nuestras reglas sobre reembolsos se proporcionan en la',
    policy: 'Política de Membresía y Reembolso',
  },
  de: {
    header: 'Sie werden belastet',
    rules: 'Unsere Rückerstattungsregeln finden Sie in der',
    policy: 'Mitgliedschafts- und Rückerstattungsrichtlinie',
  },
};

export { messages };
