import { I18n } from '@/services/i18n';

type Message = {
  expiring: string;
  getBonus: string;
  getGift: string;
  youCanCancelYourSubscription: string;
  membershipAndRefundPolicy: string;
};

const messages: I18n<Message> = {
  en: {
    expiring: 'Your Trial membership is about to expire in {days} days',
    getBonus: 'Would you like to upgrade for {price} now and get {bonus} bonus credits for FREE?',
    getGift: 'I agree',
    youCanCancelYourSubscription:
      'You can cancel your subscription anytime you want in account settings. By tapping “I agree”, we will charge you <span class="font-bold">{price}</span> for Monthly Premium Subscription. Your subscription will automatically renew for the same package length you selected every month. Our rules on refunds are provided in the ',
    membershipAndRefundPolicy: 'Membership and Refund Policy',
  },
  es: {
    expiring: 'Tu membresía de prueba está a punto de expirar en {days} días',
    getBonus:
      '¿Te gustaría actualizar por {price} ahora y obtener {bonus} créditos de bonificación GRATIS?',
    getGift: 'Estoy de acuerdo',
    youCanCancelYourSubscription:
      'Puedes cancelar tu suscripción en cualquier momento en la configuración de la cuenta. Al tocar "Estoy de acuerdo", te cobraremos <span class="font-bold">{price}</span> por la Suscripción Premium Mensual. Tu suscripción se renovará automáticamente por la misma duración del paquete que seleccionaste cada mes. Nuestras reglas sobre reembolsos están proporcionadas en la ',
    membershipAndRefundPolicy: 'Política de Membresía y Reembolsos',
  },
  de: {
    expiring: 'Ihre Testmitgliedschaft läuft in {days} Tagen ab',
    getBonus:
      'Möchten Sie jetzt für {price} ein Upgrade durchführen und {bonus} Bonus-Credits KOSTENLOS erhalten?',
    getGift: 'Ich stimme zu',
    youCanCancelYourSubscription:
      'Sie können Ihr Abonnement jederzeit in den Kontoeinstellungen kündigen. Durch das Tippen von “Ich stimme zu”, werden wir Ihnen <span class="font-bold">{price}</span> für das monatliche Premium-Abonnement berechnen. Ihr Abonnement wird automatisch für denselben von Ihnen ausgewählten Paketzeitraum jeden Monat verlängert. Unsere Rückerstattungsregeln finden Sie in der ',
    membershipAndRefundPolicy: 'Mitgliedschafts- und Rückerstattungsrichtlinie',
  },
};

export { messages };
