import { TopProfileMode } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/myprofile/v1/top_profile_pb';
import { Package } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/possibilities/v1/possibilities_pb';
import { computed } from 'vue';

import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';
import { resolveProgressInPercent } from '@/helpers/number';
import { PossibilityPackage } from '@/pages/my-profile/components/my-profile-view/components/my-profile-boosters-grid/components/profile-card/helpers/package-id-helper/package-id-helper';
import { MyProfileStateShort } from '@/pages/my-profile/store/my-profile-state';
import { purchasePossibility } from '@/services/api/possibilities/possibilities-api';
import { CommonStateCreditsFeatures } from '@/store/modules/common/common-state';
import { dispatch, getState } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

export default function usePossibilities() {
  const creditFeatureMap = computed<CommonStateCreditsFeatures>(() => {
    return getState().common.creditsFeatures;
  });

  const packages = computed<ExtractClassFields<Package>[]>(() => {
    return getState().possibility.packages;
  });

  const shortProfile = computed<MyProfileStateShort>(() => {
    return getState().myProfile.short;
  });

  const topProfileModes = computed<Partial<TopProfileMode[]>>(() => {
    return shortProfile.value.topProfileMode;
  });

  const topProfileProgress = computed<number>(() => {
    const duration = shortProfile.value?.boosterEndsDurationMs / 1000;
    const expires = shortProfile.value?.boosterEndsTimeMs / 1000;

    return resolveProgressInPercent(duration, expires);
  });

  async function purchase(pkg: PossibilityPackage) {
    customEventTrigger({
      event: 'purchase-possibility',
      packageId: pkg,
    });

    await purchasePossibility(pkg);
  }

  async function requestData(): Promise<void> {
    const packageIds: PossibilityPackage[] = Object.values(PossibilityPackage);

    await Promise.all([
      dispatch('actionCommonGetFreeCreditsFeaturesStatus'),
      dispatch('actionPossibilityBatchGetPackages', packageIds),
    ]);
  }

  return {
    creditFeatureMap,
    packages,
    topProfileProgress,
    topProfileModes,
    purchase,
    requestData,
  };
}
