import { I18n } from '@/services/i18n';

type Message = {
  add: string;
};

const messages: I18n<Message> = {
  en: {
    add: 'Add bank card',
  },
  es: {
    add: 'Agregar tarjeta bancaria',
  },
  de: {
    add: 'Bankkarte hinzufügen',
  },
};

export { messages };
