import { I18n } from '@/services/i18n';

type Message = {
  best: string;
  save: string;
  free: string;
  bonus: string;
};

const messages: I18n<Message> = {
  en: {
    best: 'Best deal',
    save: 'Save',
    free: 'FREE',
    bonus: 'Bonus',
  },
  es: {
    best: 'Mejor oferta',
    save: 'Ahorra',
    free: 'GRATIS',
    bonus: 'Bono',
  },
  de: {
    best: 'Bestes Angebot',
    save: 'Sparen',
    free: 'KOSTENLOS',
    bonus: 'Bonus',
  },
};

export { messages };
