import { I18n } from '@/services/i18n';

type Message = {
  expired: string;
  upgrade: string;
  wouldYouLikeToTakeExpired: string;
};

const messages: I18n<Message> = {
  en: {
    expired: 'Your {tier}<br />membership has expired',
    upgrade: 'Upgrade',
    wouldYouLikeToTakeExpired:
      'Would you like to take care of it and prolong now to keep using the platform?',
  },
  es: {
    expired: 'Tu membresía {tier}<br />ha expirado',
    upgrade: 'Actualizar',
    wouldYouLikeToTakeExpired:
      '¿Te gustaría encargarte de esto y prolongar ahora para seguir usando la plataforma?',
  },
  de: {
    expired: 'Ihre {tier}<br />Mitgliedschaft ist abgelaufen',
    upgrade: 'Upgrade',
    wouldYouLikeToTakeExpired:
      'Möchten Sie es jetzt erledigen und jetzt verlängern, um die Plattform weiterhin nutzen zu können?',
  },
};

export { messages };
