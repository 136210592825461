import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { computed, reactive, Ref } from 'vue';

import { getFormattedCurrency } from '@/helpers/number';
import { getPackagePrice } from '@/pages/payment/helpers/package/package';
import { usePackagePricePerWeek } from '@/pages/payment/hooks/use-package-price/use-package-price';
import { SETTINGS_DEFAULT_CURRENCY } from '@/settings';
import { ExtractClassFields } from '@/type';

type UseSelfArg = {
  selectedModel: Ref<ExtractClassFields<PremiumPackage>>;
  pkg: ExtractClassFields<PremiumPackage>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  const packagePricePerWeek = reactive(usePackagePricePerWeek(arg.pkg));

  const pkgPrice = getPackagePrice(arg.pkg);
  // Hook ----------------------------------------------------------------------

  // Computed ------------------------------------------------------------------
  const packagePricePerMonthNew = computed<string>(() => {
    return getFormattedCurrency(
      pkgPrice.totalAmount ?? 0n,
      pkgPrice.currency ?? SETTINGS_DEFAULT_CURRENCY,
    );
  });

  const packagePricePerMonthOld = computed<string>(() => {
    return getFormattedCurrency(
      pkgPrice.baseAmount ?? 0n,
      pkgPrice.currency ?? SETTINGS_DEFAULT_CURRENCY,
    );
  });

  const packagePricePerDayFormatted = computed(() => {
    return getFormattedCurrency(
      packagePricePerWeek.price.baseAmount / 7n,
      packagePricePerWeek.price.currency,
    );
  });

  const packagePricePerMonthInt = computed(() => {
    return Math.trunc(Number(pkgPrice.totalAmount) / 100);
  });

  const packagePricePerMonthCents = computed(() => {
    return Number(pkgPrice.totalAmount) % 100;
  });

  const packagePricePerDayInt = computed(() => {
    return Math.trunc(Number(packagePricePerWeek.price.totalAmount / 7n) / 100);
  });

  const packagePricePerDayCents = computed(() => {
    return Number(packagePricePerWeek.price.totalAmount / 7n) % 100;
  });

  const isSelected = computed(() => {
    return arg.selectedModel.value.id === arg.pkg.id;
  });
  // Method --------------------------------------------------------------------

  function toggleSelect(): void {
    arg.selectedModel.value = arg.pkg;
  }

  async function init() {}

  return reactive({
    init,
    toggleSelect,
    isSelected,
    packagePricePerDayCents,
    packagePricePerDayInt,
    packagePricePerMonthCents,
    packagePricePerMonthInt,
    packagePricePerDayFormatted,
    packagePricePerMonthOld,
    packagePricePerMonthNew,
    packagePricePerWeek,
  });
}
