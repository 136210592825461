let uniqNumber = 0;
export function getUniqNumber() {
  uniqNumber += 1;
  return uniqNumber;
}

export function getCommaSeparatedNumber(num: number): string {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function getFormattedCurrency(cents: number | BigInt, currency: string = 'USD'): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
  });
  return formatter.format(Number(cents) / 100);
}

export function getCurrencySymbol(currency: string): string {
  return (
    new Intl.NumberFormat('en-US', { style: 'currency', currency })
      .formatToParts(1)
      .find((x) => x.type === 'currency')?.value ?? ''
  );
}

export function getFromCents(cents: number): number {
  return +(cents / 100).toFixed(2);
}

// solution is taken from https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid/2117523#2117523
export const generateUniqueId = (): string => {
  if (import.meta.env.MODE === 'test') {
    // In a Node.js environment
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { randomUUID } = require('crypto');
    return randomUUID();
  } else {
    return crypto.randomUUID();
  }
};

// expire duration in ms
export function resolveProgressInPercent(duration: number, expires: number) {
  const secondsNow = +new Date() / 1000;
  const timeLeft = expires - secondsNow;

  return duration ? Math.floor((timeLeft * 100) / duration) : 0;
}
