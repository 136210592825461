<template>
  <TitleLayout class="payment_new_card">
    <template #header-left>
      <Back @click="back"></Back>
    </template>

    <template #default>
      <SolidPaymentMethod
        :pkg="pkg"
        :is-authorize-card-token="isSubscription"
        :is-apple-pay-enabled="isApplePayVisible"
        :is-google-pay-enabled="isGooglePayVisible"
        :is-card-enabled="true"
        google-pay-btn-type="buy"
      >
        <template #default="{ solidCardElId, solidGooglePayElId, solidApplePayElId }">
          <div class="title-h1">{{ t('choose') }}</div>

          <div class="flex mb-8">
            <div
              class="bg-success-500 w-1/2 p-3 mr-2 flex flex-col items-center justify-center rounded-xl border border-success"
            >
              <img src="./images/paypal.svg?url" alt="Paypal" />
            </div>
            <div
              class="w-1/2 p-3 ml-2 flex flex-col items-center rounded-xl border border-dark-400 cursor-pointer"
              @click="self.setIsCardFormVisible(true)"
            >
              <div class="mb-1 text-button2 font-semibold">{{ t('creditCard') }}</div>
              <div v-if="self.isGooglePayAvailable || self.isApplePayAvailable" class="flex gap-2">
                <span class="text-button2 font-semibold text-dark-200">{{ t('or') }}</span>
                <img v-if="self.isApplePayAvailable" src="./images/apple-pay.svg?url" alt="" />
                <img v-if="self.isGooglePayAvailable" src="./images/google-pay.svg?url" alt="" />
              </div>

              <div v-else class="flex gap-2">
                <img src="./images/credit-card.svg?url" alt="Credit card" />
              </div>

              <!--              <template v-else>-->
              <!--                <div class="mb-2">credit card</div>-->
              <!--                <img src="./images/credit-card.svg?url" alt="Credit card" />-->
              <!--              </template>-->

              <!--              <img src="./images/credit-card.svg?url" alt="Credit card" />-->
            </div>
          </div>

          <template v-if="areMethodsLoaded">
            <div class="payment-express">
              <div v-if="!isSubscription">
                <div class="payment_btn_wrap">
                  <div class="payment_btn_wrap-label">{{ t('expressCheckout') }}</div>
                  <SolidPaypalButton v-if="self.isPaypalAvailable" :pkg="pkg"></SolidPaypalButton>
                </div>
              </div>
            </div>

            <!--            <div class="text-dark-200 text-center mt-4">OR</div>-->
            <!--            <div v-if="!isPaypalImproveSplitV2" class="mt-4">-->
            <!--              <div :id="solidGooglePayElId" class="mb-2 h-10"></div>-->
            <!--              <div :id="solidApplePayElId" class="h-10"></div>-->
            <!--            </div>-->

            <SolidPaymentMethodModalLayout
              v-show="self.isCardFormVisible"
              :pkg="pkg"
              payment-flow="default"
              :is-authorize-card-token="isSubscription"
              @close="!self.isPaypalAvailable ? back() : self.setIsCardFormVisible(false)"
            >
              <template #title>{{ t('paymentDetails') }}</template>

              <template #default>
                <div class="relative">
                  <div :id="solidGooglePayElId" class="mb-2"></div>
                  <div :id="solidApplePayElId" class="mb-2"></div>

                  <div :id="solidCardElId" class="solid-payment-form-container"></div>

                  <template v-if="!isTermsChecked && !isFromLanding">
                    <div
                      class="absolute bg-black opacity-50 left-0 top-0 right-0 bottom-0 rounded"
                    ></div>

                    <div
                      class="absolute left-0 top-0 right-0 bottom-0 flex items-center justify-center text-white"
                    >
                      {{ t('terms') }}
                    </div>
                  </template>
                </div>

                <div v-if="!isFromLanding" class="custom-checkbox accept_form is-invalid mt-5">
                  <TermsCheckboxInput v-model="isTermsChecked"></TermsCheckboxInput>
                </div>
              </template>
            </SolidPaymentMethodModalLayout>
          </template>
          <div v-else>
            <SpinnerLoader />
          </div>
        </template>
      </SolidPaymentMethod>
    </template>
  </TitleLayout>
</template>

<script lang="ts" setup>
import { computed, onMounted, PropType, ref } from 'vue';

import TermsCheckboxInput from '@/components/inputs/checkbox/TermsCheckboxInput.vue';
import Back from '@/components/layout/components/header/components/back/Back.vue';
import TitleLayout from '@/components/layout/TitleLayout.vue';
import SpinnerLoader from '@/components/loader/SpinnerLoader.vue';
import SolidPaymentMethod from '@/components/payment/components/payment-method/components/solid/solid-payment-method/SolidPaymentMethod.vue';
import SolidPaymentMethodModalLayout from '@/components/payment/components/payment-method/components/solid/solid-payment-method-modal-layout/SolidPaymentMethodModalLayout.vue';
import SolidPaypalButton from '@/components/payment/components/payment-method/components/solid/solid-paypal-button/SolidPaypalButton.vue';
import { usePayment } from '@/components/payment/hooks/use-payment/use-payment';
import { trackPaymentFlow } from '@/helpers/track/track-payment/track-payment';
import { useHistoryChange } from '@/hooks/use-history-change/use-history-change';
import { useSelf } from '@/pages/payment/components/payment-method/components/payment-method-add-paypal-first/hooks/use-self/use-self';
import { PaymentStatePackage } from '@/pages/payment/store/payment-state';
import { useI18n } from '@/services/i18n';
import { getStore } from '@/store/store-helper';

import { messages } from './i18n';

const props = defineProps({
  pkg: {
    type: Object as PropType<PaymentStatePackage>,
    required: true,
  },
  isGooglePayVisible: {
    type: Boolean,
    default: true,
  },
  isApplePayVisible: {
    type: Boolean,
    default: true,
  },
  isSubscription: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['back']);

const { t } = useI18n({ messages });

const areMethodsLoaded = ref(false);

const isTermsChecked = ref(false);

const self = useSelf({ pkg: props.pkg });

// Hook ------------------------------------------------------------------------

const isFromLanding = computed((): boolean => {
  return getStore().state.common.index.isFromLanding;
});

function back() {
  emit('back');
}

onMounted(async () => {
  trackPaymentFlow({
    step: 'add',
  });
});

usePayment({
  loaded: () => {
    areMethodsLoaded.value = true;

    if (!self.isPaypalAvailable) {
      self.setIsCardFormVisible(true);
    }
  },
});

useHistoryChange(back).init();
</script>

<style scoped lang="scss" src="./style.scss"></style>
