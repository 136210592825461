import { I18n } from '@/services/i18n';

type Message = {
  continue: string;
  save: string;
  saveUp: string;
  limitedOffer: string;
  takeIt: string;
  takeItLater: string;
  expireOffer: string;
  week: string;
  free: string;
  hello: string;
  youHaveUnlocked: string;
};

const messages: I18n<Message> = {
  en: {
    continue: 'Continue',
    save: 'Save {percent}%',
    saveUp: 'Save up to {percent}%',
    limitedOffer: 'Special Bonus Unlocked',
    takeIt: 'TAKE IT',
    takeItLater: 'No thanks, I’d rather pay full price',
    expireOffer:
      'Although your trial offer has expired we offer you a personal discount -{discountPercent}%',
    week: 'week',
    free: 'Free',
    hello: 'Hello',
    youHaveUnlocked: 'You have unlocked a special offer!',
  },
  es: {
    continue: 'Continuar',
    save: 'Ahorra {percent}%',
    saveUp: 'Ahorra hasta {percent}%',
    limitedOffer: '¡Bonificación especial desbloqueada!',
    takeIt: 'TÓMALO',
    takeItLater: 'No gracias, prefiero pagar el precio completo',
    expireOffer:
      'Aunque tu oferta de prueba ha expirado, te ofrecemos un descuento personal de -{discountPercent}%',
    week: 'semana',
    free: 'Gratis',
    hello: 'Hola',
    youHaveUnlocked: '¡Has desbloqueado una oferta especial!',
  },
  de: {
    continue: 'Weiter',
    save: 'Sparen {percent}%',
    saveUp: 'Bis zu {percent}% sparen',
    limitedOffer: 'Spezialbonus freigeschaltet',
    takeIt: 'NEHMEN SIE ES',
    takeItLater: 'Nein danke, ich zahle lieber den vollen Preis',
    expireOffer:
      'Obwohl Ihr Testangebot abgelaufen ist, bieten wir Ihnen einen persönlichen Rabatt von -{discountPercent}%',
    week: 'Woche',
    free: 'Kostenlos',
    hello: 'Hallo',
    youHaveUnlocked: 'Sie haben ein spezielles Angebot freigeschaltet!',
  },
};

export { messages };
