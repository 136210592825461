import { I18n } from '@/services/i18n';

type Message = {
  newVisit: string;
  youHaveSomeThings: string;
  meet: string;
};

const messages: I18n<Message> = {
  en: {
    newVisit: 'New visit from {name}',
    youHaveSomeThings: 'You have some things in common. How about getting to know them better?',
    meet: 'Meet {name}',
  },
  es: {
    newVisit: 'Nueva visita de {name}',
    youHaveSomeThings: 'Tienen algunas cosas en común. ¿Qué tal conocerse mejor?',
    meet: 'Conoce a {name}',
  },
  de: {
    newVisit: 'Neuer Besuch von {name}',
    youHaveSomeThings:
      'Sie haben einige gemeinsame Interessen. Wie wäre es, sie besser kennenzulernen?',
    meet: 'Treffen Sie {name}',
  },
};

export { messages };
