import { I18n } from '@/services/i18n';

type Message = {
  headerPerWeek: string;
  trialOfferUnlocked: string;
  continue: string;
};

const messages: I18n<Message> = {
  en: {
    headerPerWeek: '7 DAYS<br />OF PREMIUM FOR {price}',
    trialOfferUnlocked: 'TRIAL OFFER UNLOCKED',
    continue: 'TRY NOW',
  },
  es: {
    headerPerWeek: '7 DÍAS<br />DE PREMIUM POR {price}',
    trialOfferUnlocked: 'OFERTA DE PRUEBA DESBLOQUEADA',
    continue: 'PRUEBA AHORA',
  },
  de: {
    headerPerWeek: '7 TAGE<br />PREMIUM FÜR {price}',
    trialOfferUnlocked: 'TESTANGEBOT FREIGESCHALTET',
    continue: 'JETZT AUSPROBIEREN',
  },
};

export { messages };
