import { I18n } from '@/services/i18n';

type Message = {
  greet: string;
  greetChatRequest: string;
  title: string;
  startChat: string;
  freeGiftIsAvailable: string;
  send: string;
  chatRequest: string;
};

const messages: I18n<Message> = {
  en: {
    greet:
      'Now {name} will see that you are interested in them. Draw attention to yourself with a welcome message.',
    greetChatRequest:
      'Now {name} will see that you are interested in chatting. You can send them 5 messages and wait for a response.',
    title: 'Nice move!',
    startChat: 'Start chat',
    chatRequest: 'Chat Request',
    // @TODO uncomment `freeGiftIsAvailable` when we'll have free gifts in the task below
    // https://gdx.myjetbrains.com/youtrack/issue/GDP-1203
    // freeGiftIsAvailable: 'Free gift is available for sending',
    freeGiftIsAvailable: 'Free gift is available for sending',
    send: 'SEND',
  },
  es: {
    greet:
      'Ahora {name} verá que estás interesado en ellos. Llama la atención con un mensaje de bienvenida.',
    greetChatRequest:
      'Ahora {name} verá que estás interesado en chatear. Puedes enviarles 5 mensajes y esperar una respuesta.',
    title: '¡Buena jugada!',
    startChat: 'Iniciar chat',
    chatRequest: 'Solicitud de chat',
    // @TODO uncomment `freeGiftIsAvailable` when we'll have free gifts in the task below
    // https://gdx.myjetbrains.com/youtrack/issue/GDP-1203
    // freeGiftIsAvailable: 'Free gift is available for sending',
    freeGiftIsAvailable: 'Regalo gratuito disponible para enviar',
    send: 'ENVIAR',
  },
  de: {
    greet:
      'Jetzt wird {name} sehen, dass Sie an ihnen interessiert sind. Ziehe die Aufmerksamkeit mit einer Begrüßungsnachricht auf dich.',
    greetChatRequest:
      'Jetzt wird {name} sehen, dass Sie am Chat interessiert sind. Sie können ihnen 5 Nachrichten senden und auf eine Antwort warten.',
    title: 'Guter Zug!',
    startChat: 'Chat starten',
    chatRequest: 'Chat-Anfrage',
    // @TODO uncomment `freeGiftIsAvailable` when we'll have free gifts in the task below
    // https://gdx.myjetbrains.com/youtrack/issue/GDP-1203
    // freeGiftIsAvailable: 'Free gift is available for sending',
    freeGiftIsAvailable: 'Kostenloses Geschenk kann gesendet werden',
    send: 'SENDEN',
  },
};

export { messages };
