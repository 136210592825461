import { I18n } from '@/services/i18n';

type SubscriptionFeature =
  | 'knowYourMatch'
  | 'getUnlimitedLikes'
  | 'meetYourType'
  | 'seeWhoChecksYouOut'
  | 'chatWithoutLimits'
  | 'noMoreSecrets'
  | 'neverMissTheHit'
  | 'ads';

type Message = {
  slider: Record<
    SubscriptionFeature,
    {
      header: string;
      content: string;
    }
  >;
};

const messages: I18n<Message> = {
  en: {
    slider: {
      knowYourMatch: {
        header: 'Know Your Match',
        content: 'Read the full profile description of any person here',
      },
      getUnlimitedLikes: {
        header: 'Get Unlimited Likes',
        content: 'Now you can send only 3 likes daily',
      },
      meetYourType: {
        header: 'Meet Your Type',
        content: 'Use advanced filters to find your type of person',
      },
      seeWhoChecksYouOut: {
        header: 'See Who Checks You Out',
        content: 'Your Activity is blured, unlock it to meet everyone',
      },
      chatWithoutLimits: {
        header: 'Chat Without Limits',
        content: 'No limits for chatting and messaging',
      },
      noMoreSecrets: {
        header: 'No More Secrets',
        content: 'See everyone who had checked your compatibility with them',
      },
      neverMissTheHit: {
        header: 'Never Miss The Hit',
        content: 'See your outgoing activity and revisit anyone at anytime',
      },
      ads: {
        header: 'No Ads',
        content: 'Tired of ads? Upgrade and don’t get bothered by advertising',
      },
    },
  },
  es: {
    slider: {
      knowYourMatch: {
        header: 'Conoce tu pareja',
        content: 'Lee la descripción completa del perfil de cualquier persona aquí',
      },
      getUnlimitedLikes: {
        header: 'Obtén Me Gustas Ilimitados',
        content: 'Ahora puedes enviar solo 3 me gusta diarios',
      },
      meetYourType: {
        header: 'Conoce tu tipo',
        content: 'Usa filtros avanzados para encontrar tu tipo de persona',
      },
      seeWhoChecksYouOut: {
        header: 'Vea quién te revisa',
        content: 'Tu actividad está borrosa, desbloquéala para conocer a todos',
      },
      chatWithoutLimits: {
        header: 'Chat sin límites',
        content: 'No hay límites para chatear y enviar mensajes',
      },
      noMoreSecrets: {
        header: 'No más secretos',
        content: 'Vea a todos los que han verificado su compatibilidad contigo',
      },
      neverMissTheHit: {
        header: 'Nunca pierdas la oportunidad',
        content: 'Vea su actividad saliente y revisite a cualquier persona en cualquier momento',
      },
      ads: {
        header: 'Sin anuncios',
        content: '¿Cansado de los anuncios? Mejora tu cuenta y no te molestarán las publicidades',
      },
    },
  },
  de: {
    slider: {
      knowYourMatch: {
        header: 'Lernen Sie Ihren Match kennen',
        content: 'Lesen Sie die vollständige Profilbeschreibung jeder Person hier',
      },
      getUnlimitedLikes: {
        header: 'Unbegrenzte Likes erhalten',
        content: 'Jetzt können Sie nur 3 Likes täglich senden',
      },
      meetYourType: {
        header: 'Lernen Sie Ihren Typ kennen',
        content: 'Verwenden Sie erweiterte Filter, um Ihren Typ zu finden',
      },
      seeWhoChecksYouOut: {
        header: 'Sehen Sie, wer Sie ansieht',
        content: 'Ihre Aktivität ist unscharf, entsperren Sie es, um alle zu treffen',
      },
      chatWithoutLimits: {
        header: 'Chatten ohne Begrenzungen',
        content: 'Keine Begrenzungen für Chatten und Nachrichten',
      },
      noMoreSecrets: {
        header: 'Keine Geheimnisse mehr',
        content: 'Sehen Sie alle, die Ihre Kompatibilität mit ihnen überprüft haben',
      },
      neverMissTheHit: {
        header: 'Verpassen Sie nie den Hit',
        content: 'Sehen Sie Ihre ausgehende Aktivität und treffen Sie jeden jederzeit erneut',
      },
      ads: {
        header: 'Keine Werbung',
        content: 'Genervt von Werbung? Upgrade und lassen Sie sich nicht mehr durch Werbung stören',
      },
    },
  },
};

export { messages };
