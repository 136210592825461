import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  description: string;
  actionButtonText: string;
};

const messages: I18n<Message> = {
  en: {
    header: "You're double checked by",
    description: '{name} checked their astrological & romantic compatibility with you',
    actionButtonText: 'Visit profile',
  },
  es: {
    header: 'Te han verificado dos veces',
    description: '{name} verificó su compatibilidad astrológica y romántica contigo',
    actionButtonText: 'Visitar perfil',
  },
  de: {
    header: 'Sie werden doppelt von geprüft',
    description: '{name} hat ihre astrologische & romantische Kompatibilität mit Ihnen überprüft',
    actionButtonText: 'Profil besuchen',
  },
};

export { messages };
