import { reactive, ref } from 'vue';

import { customEventTrigger, PromoShowPurchaseSource } from '@/helpers/gtm-triggers/gtm-triggers';
import { trackPaymentPromo } from '@/helpers/track/track-payment/track-payment';
import { PaymentStatePackagePremium } from '@/pages/payment/store/payment-state';
import { dispatch } from '@/store/store-helper';

type UseSelfArg = {
  source: PromoShowPurchaseSource;
  handleNext: (packageSelected: PaymentStatePackagePremium) => void;
  handleClose: () => void;
  handleSelect: (packageSelected: PaymentStatePackagePremium) => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  const packageList = ref<PaymentStatePackagePremium[]>([]);
  const packageSelected = ref<PaymentStatePackagePremium>(packageList.value[0]);
  const page = ref('paywall');

  // Hook ----------------------------------------------------------------------

  // Computed ------------------------------------------------------------------

  // Method --------------------------------------------------------------------
  async function fetchPackageList() {
    const response = await dispatch('actionCommonGetAvailablePromos');
    packageList.value = response.packages;
  }

  function nextPage(): void {
    arg.handleNext(packageSelected.value);
  }

  function setPage(pageArg: string): void {
    page.value = pageArg;
  }

  function back() {
    arg.handleClose();
  }

  function select(path?: string) {
    customEventTrigger({
      event: 'page_show',
      name: 'payment_flow',
      step: 'add',
      click_source: path || 'continue',
    });

    arg.handleSelect(packageSelected.value);
  }

  async function init() {
    await fetchPackageList();

    if (packageList.value.length > 0) {
      packageSelected.value = packageList.value[0];
    }

    trackPaymentPromo({
      from: arg.source,
      popup: 'premium-ntf-v2',
      type: 'premium',
    });
  }

  return reactive({
    packageList,
    packageSelected,

    init,
    setPage,
    page,
    nextPage,
    back,
    select,
  });
}
