import { bq } from '@/services/bq/bq';

function gtag() {
  if (window.dataLayer) {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  }
  // eslint-disable-next-line prefer-rest-params
  console.log('gtag', arguments);
}

window.gtag = gtag;

let isInited = false;
function init() {
  if (!isInited) {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-586411494';
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    // @ts-expect-error
    gtag('js', new Date());
    // @ts-expect-error
    gtag('config', 'AW-586411494');

    isInited = true;
  }
}

function track(paramMap: Record<string, unknown>) {
  // @ts-expect-error
  gtag('event', 'conversion', paramMap);
}

function trackAccountCreated() {
  track({ send_to: 'AW-586411494/RcpDCJiYiaoaEObbz5cC' });
  bq.track('account_created', {});
}

function trackProfileCreated() {
  track({ send_to: 'AW-586411494/7EU9CN2biaoaEObbz5cC' });
  bq.track('profile_created', {});
}

type TrackPurchaseArg = {
  value: number;
  currency: string;
  transactionId: string;
};
function trackPurchase(arg: TrackPurchaseArg) {
  track({
    send_to: 'AW-586411494/13iWCJWYiaoaEObbz5cC',
    value: arg.value,
    currency: arg.currency,
    transaction_id: arg.transactionId,
  });
  bq.track('purchase', arg);
}

export const googleAdwords = {
  init,
  trackAccountCreated,
  trackProfileCreated,
  trackPurchase,
};
