import { I18n } from '@/services/i18n';

type Message = {
  exclusiveOneTimeOffer: string;
  congratulations: string;
  youHaveSuccessfullyTopped: string;
  free: string;
  subscribeForJust: string;
  buyFree: string;
  absolutelyFree: string;
  forSevenDays: string;
  or: string;
  subscriptionAdvantages: string;
};

const messages: I18n<Message> = {
  en: {
    exclusiveOneTimeOffer: 'EXCLUSIVE ONE-TIME OFFER',
    congratulations: 'Congratulations,',
    youHaveSuccessfullyTopped: 'You’ve successfully topped-<br />up your credits balance',
    free: 'Free trial',
    subscribeForJust: 'Subscribe for just {price}',
    buyFree: 'Try trial for FREE',
    absolutelyFree: 'ABSOLUTELY FREE',
    forSevenDays: 'for 7 days',
    or: 'OR',
    subscriptionAdvantages: 'Subscription advantages',
  },
  es: {
    exclusiveOneTimeOffer: 'OFERTA EXCLUSIVA ÚNICA',
    congratulations: 'Felicidades,',
    youHaveSuccessfullyTopped: 'Has recargado con éxito tu saldo de créditos',
    free: 'Prueba gratuita',
    subscribeForJust: 'Suscríbete por solo {price}',
    buyFree: 'Prueba la prueba GRATIS',
    absolutelyFree: 'ABSOLUTAMENTE GRATIS',
    forSevenDays: 'por 7 días',
    or: 'O',
    subscriptionAdvantages: 'Ventajas de la suscripción',
  },
  de: {
    exclusiveOneTimeOffer: 'EXKLUSIVES EINMALIGES ANGEBOT',
    congratulations: 'Herzlichen Glückwunsch,',
    youHaveSuccessfullyTopped: 'Sie haben Ihren Credits-Bestand erfolgreich aufgeladen',
    free: 'Kostenlose Testversion',
    subscribeForJust: 'Abonnieren für nur {price}',
    buyFree: 'Probieren Sie die Testversion KOSTENLOS aus',
    absolutelyFree: 'ABSOLUT KOSTENLOS',
    forSevenDays: 'für 7 Tage',
    or: 'ODER',
    subscriptionAdvantages: 'Abonnementvorteile',
  },
};

export { messages };
