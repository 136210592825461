import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  modalText: string;
  becomeStar: string;
  hour: string;
  hours: string;
  minutes: string;
  forJust: string;
  credits: string;
  notEnough: string;
  success: string;
  explain: string;
  buyMore: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'Boost your profile to become more popular!',
    modalText:
      'The peak hours are 6 to 10 pm. Move up to the top for {duration} by using a "{title}" popularity booster to get noticed far more often.',
    becomeStar: 'Become a star',
    hour: 'hour',
    hours: 'hours',
    minutes: 'minutes',
    forJust: 'For just',
    credits: 'credits',
    notEnough: 'Not enough credits',
    success: 'You successfully purchased credits!',
    explain:
      "Now you have {creditsCount} credits. But '{title}' costs {price} credits. It means that you still need to buy {lack} more credits to increase your popularity.",
    buyMore: 'Buy more credits',
  },
  es: {
    header: '¡Impulsa tu perfil para ser más popular!',
    modalText:
      'Las horas pico son de 6 a 10 pm. Sube a la cima por {duration} usando un impulsador de popularidad "{title}" para que te noten con más frecuencia.',
    becomeStar: 'Conviértete en una estrella',
    hour: 'hora',
    hours: 'horas',
    minutes: 'minutos',
    forJust: 'Por solo',
    credits: 'créditos',
    notEnough: 'No tienes suficientes créditos',
    success: '¡Has comprado créditos exitosamente!',
    explain:
      "Ahora tienes {creditsCount} créditos. Pero '{title}' cuesta {price} créditos. Eso significa que aún necesitas comprar {lack} créditos más para aumentar tu popularidad.",
    buyMore: 'Compra más créditos',
  },
  de: {
    header: 'Pusht dein Profil, um beliebter zu werden!',
    modalText:
      'Die Stoßzeiten sind von 18 bis 22 Uhr. Steige für {duration} an die Spitze, indem du einen "{title}" Popularitätsbooster verwendest, um viel öfter bemerkt zu werden.',
    becomeStar: 'Werde ein Star',
    hour: 'Stunde',
    hours: 'Stunden',
    minutes: 'Minuten',
    forJust: 'Für nur',
    credits: 'Credits',
    notEnough: 'Nicht genug Credits',
    success: 'Sie haben erfolgreich Credits erworben!',
    explain:
      "Jetzt haben Sie {creditsCount} Credits. Aber '{title}' kostet {price} Credits. Das bedeutet, dass Sie noch {lack} weitere Credits kaufen müssen, um Ihre Beliebtheit zu steigern.",
    buyMore: 'Mehr Credits kaufen',
  },
};

export { messages };
