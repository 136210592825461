import { createClient, type PromiseClient } from '@connectrpc/connect';
import { AnalyticsAPI } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/analytics/analytics_api_connect';
import {
  RecordEventRequest,
  type RecordEventResponse,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/analytics/analytics_api_pb';
import type { Event } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/analytics/event_pb';

import { getGrpcWebTransport } from '@/services/api/grpc-api';
import { ExtractClassFields } from '@/type';

let client: PromiseClient<typeof AnalyticsAPI>;

function getClient() {
  if (!client) {
    client = createClient(AnalyticsAPI, getGrpcWebTransport());
  }

  return client;
}

function recordEvent(event: ExtractClassFields<Event>): Promise<RecordEventResponse> {
  const request = new RecordEventRequest({
    event,
  });

  return getClient().recordEvent(request);
}

export const analyticsApi = {
  recordEvent,
};
