import { I18n } from '@/services/i18n';

type Message = {
  userUploadedPhoto: string;
  view: string;
};

const messages: I18n<Message> = {
  en: {
    userUploadedPhoto: 'uploaded photo!',
    view: 'VIEW',
  },
  es: {
    userUploadedPhoto: '¡ha subido una foto!',
    view: 'VER',
  },
  de: {
    userUploadedPhoto: 'Foto hochgeladen!',
    view: 'ANSEHEN',
  },
};

export { messages };
